import React from 'react';
import './Blog.css';
import Timeline from "./Components/Timeline";

const Blog = () => {
    return (
        <div className="container my-5">
            <section id="Einleitung_Blog" className="text-center mb-5">
                <h1 id="Einleitung_Blog_header" className="display-4">Wohin geht es als Nächstes?</h1>
                <p id="Einleitung_Blog_text" className="lead">
                    Folgt mir auf meinem Weg und in jeder Etappe meiner Entdeckungsreise durch die Welt,
                    <br />
                    durch unterschiedliche Kulturen, zu den faszinierendsten Städten
                    <br />
                    und den beeindruckendsten Panoramen.
                </p>
            </section>

            <div className="row text-center mb-5">
                <div className="col-md-4">
                    <div className="p-4 bg-light rounded shadow-sm">
                        <h2 className="display-5">4/7</h2>
                        <p className="font-weight-bold">KONTINENTE</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-4 bg-light rounded shadow-sm">
                        <h2 className="display-5">21/195</h2>
                        <p className="font-weight-bold">LÄNDER</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-4 bg-light rounded shadow-sm">
                        <h2 className="display-5">3/5</h2>
                        <p className="font-weight-bold">OZEANE</p>
                    </div>
                </div>
            </div>
            <div>
                <Timeline/>
            </div>
        </div>
    );
};

export default Blog;
