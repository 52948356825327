import './AboutMe.css';
import Me_Coding from './Files/Coding.jpg';
import Me_IMG from './Files/Me.jpg';

const AboutMe = () => {
    return (
        <div className="container my-5">
            <div className="row mb-4">
                <div className="col-md-6 align-self-center">
                    <h1>Wie hat es begonnen?</h1>
                    <p>Schon früh haben wir das Reisen für uns entdeckt. Eine größere Reise durch den Westen der USA
                        brachte uns schließlich dazu, einen Blog für die Daheimgebliebenen zu schreiben – wenn auch
                        anfänglich eher unfreiwillig. Doch so begann unsere Leidenschaft für das Bloggen.
                        <br/><br/>
                        Mit unserem Papa haben wir in den Ferien oft Tagebuch geführt. Diese Tradition führte dazu,
                        dass Gina, meine Schwester und ich beschlossen, 2022 während unserer Reise nach Belize ein
                        Reisetagebuch zu schreiben.
                        <br/><br/>
                        Heute findet ihr mich auf dieser Homepage. Seit der Belizereise
                        dokumentiere ich jede meiner Reisen. Diese Webseite entstand, weil ich auf meiner Jimdo-Seite
                        zu wenig Speicherplatz hatte und meine Begeisterung für Webentwicklung wiederentdeckt habe.
                    </p>
                </div>
                <div className="col-md-6 IMG_coding">
                    <img src={Me_Coding} className="img-fluid rounded" alt="Me Coding" loading="eager" rel="preload"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <img src={Me_IMG} className="img-fluid rounded" alt="Me" loading="eager" rel="preload"/>
                </div>
                <div className="col-md-6 align-self-center">
                    <h1>Wer bin ich?</h1>
                    <p>
                        Mein Name ist Laura und meine große Leidenschaft ist das Reisen. Dank meiner Eltern, die selbst
                        leidenschaftliche Reisende sind, hatte ich schon früh die Möglichkeit, viele Länder zu entdecken.
                        <br/><br/>
                        Dadurch hat sich auch meine Motivation fürs Reisen entwickelt.
                    </p>
                    <div className="steckbrief bg-light" >
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>Alter: </strong> 24</li>
                            <li className="list-group-item"><strong>Hobbies: </strong> Reisen, Tauchen, Lesen</li>
                            <li className="list-group-item"><strong>Reiseziele dieses Jahr: </strong>Norwegen</li>
                            <li className="list-group-item"><strong>Lieblingsreisezitat: </strong> "Man muss reisen, um zu lernen." – Mark Twain</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AboutMe;