const Dashboard_Welcome = () => {
    return (
        <div>
            <p className="text-center m-5">Damit die Daheimgebliebenen auch etwas von meinen Abenteuern haben, teile ich hier meine Erlebnisse.
                <br/>
                Dazu führe ich auf meinen Reisen ein Tagebuch, welches ihr unter "Blog" findet.
                <br/>
                <br/>
                Viel Spass und über Feedback freue ich mich immer!
            </p>
        </div>
    );
};

export default Dashboard_Welcome;