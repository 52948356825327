import BlogPostContainer from "./BlogPostContainer";

const Split = () => {
    const blogPosts = [
        {
            date: "30.09.2023",
            title: "3. Tag",
            content: (
                <>
                    Welcome to the last day
                    <br/><br/>
                    Heute haben wir einen Bootsausflug gemacht. Frühmorgens ging es los, Treffpunkt Split. Ziel waren
                    drei
                    Inseln und ein Badestopp. Zuerst sind wir einige Zeit gefahren bevor wir bei der "Blauen Grotte"
                    angekommen sind.
                    Dies war sehr Eindrücklich, da der Eingang sagen wir schön Ausgedrückt "klein" war.
                    <br/>
                    <br/>
                    Als nächstes gab es einen Halt in einer Bucht namens Blue Lagoon. Dort konnten wir einen Cocktail
                    drinken und
                    Baden.
                    <br/>
                    <br/>
                    Nächster Halt war Komiza auf der Insel Vis. Das Städtchen ist sehr schön. Wir sind auf den Hausberg
                    gelaufen um eine tolle Aussicht zu haben und zurück im Stadtkern haben wir uns ein Eis gegönnt.
                    <br/>
                    <br/>
                    Dies ist schon der letzte Tag in Kroatien, Morgen gehts zurück in die Schweiz.
                </>
            ),
            images: [
                "/images/03_Split/3.1.jpg",
                "/images/03_Split/3.2.jpg",
                "/images/03_Split/3.3.jpg",
                "/images/03_Split/3.4.jpg",
                "/images/03_Split/3.5.jpg",
                "/images/03_Split/3.6.jpg",
                "/images/03_Split/3.7.jpg",
                "/images/03_Split/3.8.jpg",
                "/images/03_Split/3.9.jpg"
            ]
        },
        {
            date: "29.09.2023",
            title: "2. Tag",
            content: (
                <>
                    Heute ging es direkt vom AirBnB früh Morgens in den Norden.
                    <br/><br/>
                    Erstes Ziel: Nationalpark Plitvicer
                    <br/>
                    Fahrzeit ca. 3h fast alles Autobahn was in Kroatien sehr angenehm ist. Im Nationalpark hat es
                    diverse Wanderwege
                    wir haben uns für einen einfachen entschieden aus Zeitgründen. Es war sehr schön die Seen und
                    Wasserfälle sind super klar
                    und wunderschön Blau. Das Spazieren den Wegen entlang ist sehr schön gemacht mit Holzbrücken,
                    Waldwegen und einfachen Strassen. Nach einer kurzen Verpflegung in der nähe des Parkplatzes ging es
                    weiter.
                    <br/><br/>
                    Nächstes Ziel: Eye of the World (Cetina River Spring)
                    <br/>
                    Fahrzeit ca. 2h wieder Richtung Süden. Das Eye of the World in Kroatien ist eine 150m tiefe Quelle.
                    Von
                    oben sieht es aus wie ein Auge, so kam die Quelle zu ihrem Namen. Zu Fuss sieht man nicht alles aber
                    mit der Drohne ist es umso beeindruckender.
                    <br/><br/>
                    Danach ging es für uns zurück zum AirBnB und am Abend wieder nach Split zum Essen und den Abend
                    ausklingen zu lassen.
                </>
            ),
            images: [
                "/images/03_Split/2.1.jpg",
                "/images/03_Split/2.2.jpg",
                "/images/03_Split/2.3.jpg",
                "/images/03_Split/2.4.jpg",
                "/images/03_Split/2.5.jpg",
                "/images/03_Split/2.6.jpg",
                "/images/03_Split/2.7.jpg",
                "/images/03_Split/2.8.jpg"
            ]
        },
        {
            date: "28.09.2023",
            title: "1. Tag",
            content: (
                <>
                    Unser Flug ging von Basel nach Split. Nach Ankunft haben wir unseren Flitzer abgeholt. Wir haben uns
                    für ein
                    AirBnB Südlich von Split entschieden, das AirBnB ist direkt am Meer. Die Aussicht ist toll und die
                    Unterkunft sehr schön.
                    <br/><br/>
                    Nachdem wir eingekauft und uns eingerichtet haben ging es am Abend nach Split in die Stadt. Das
                    Abendessen
                    war toll und das Herumspazieren sehr schön.
                </>
            ),
            images: [
                "/images/03_Split/1.1.jpg",
                "/images/03_Split/1.2.jpg",
                "/images/03_Split/1.3.jpg",
                "/images/03_Split/1.4.jpg",
                "/images/03_Split/1.5.jpg",
                "/images/03_Split/1.6.jpg"
            ]
        }
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Split, Kroatien</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    key={index}
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Split;