import {useEffect} from "react";

const blogs = [
    { id: 7, title: 'Norwegen', image: '/images/Dashboard/NorwegenFlagge.jpg', link: '/Norwegen' },
    { id: 6, title: 'Zandvoort', image: '/images/Dashboard/Zandvoort.jpg', link: '/Zandvoort' },
    { id: 5, title: 'Sri Lanka', image: '/images/Dashboard/SriLanka.jpg', link: '/SriLanka' },
    { id: 4, title: 'Thailand', image: '/images/Dashboard/Thailand.jpg', link: '/Thailand' },
    { id: 3, title: 'Split', image: '/images/Dashboard/Split.jpg', link: '/Split' },
    { id: 2, title: 'Mauritius', image: '/images/Dashboard/Mauritius.jpg', link: '/Mauritius' },
    { id: 1, title: 'Belize', image: '/images/Dashboard/Belize.jpg', link: '/Belize' },
];

const LinksToBlog = () => {
    useEffect(() => {
        const handleScroll = () => {
            const blogPosts = document.querySelectorAll('.blog-post');
            const windowHeight = window.innerHeight;

            blogPosts.forEach(post => {
                const postTop = post.getBoundingClientRect().top;

                if (postTop < windowHeight - 100) {
                    post.style.opacity = 1;
                    post.style.transform = 'translateY(0)';
                } else {
                    post.style.opacity = 0;
                    post.style.transform = 'translateY(100px)';
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="BlogSection container-fluid">
            <div className="row">
                {blogs.map(blog => (
                    <div key={blog.id} className="col-12 col-md-4 blog-post">
                        <div className="blog-post-inner">
                            <a href={blog.link}>
                                <img src={blog.image} alt={blog.title} className="blog-img" loading="lazy" rel="preload"/>
                                <h3 className="blog-title">{blog.title}</h3>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default LinksToBlog