import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../01_Dashboard/Dashboard.jsx";
import ErrorPage from "./ErrorPage.jsx";
import AboutMe from "../02_AboutMe/AboutMe";
import Blog from "../03_Blog/Blog";
import Belize from "../03_Blog/Components/01_Belize";
import Mauritius from "../03_Blog/Components/02_Mauritius";
import Thailand from "../03_Blog/Components/04_Thailand";
import SriLanka from "../03_Blog/Components/05_SriLanka";
import Split from "../03_Blog/Components/03_Split";
import Zandvoort from "../03_Blog/Components/06_Zandvoort";
import Norwegen from "../03_Blog/Components/07_Norwegen";



export const router = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/aboutme",
        element: <AboutMe/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/blog",
        element: <Blog/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/belize",
        element: <Belize/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mauritius",
        element: <Mauritius/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/split",
        element: <Split/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/thailand",
        element: <Thailand/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/srilanka",
        element: <SriLanka/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/zandvoort",
        element: <Zandvoort/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/norwegen",
        element: <Norwegen/>,
        errorElement: <ErrorPage/>
    },
]);
