import BlogPostContainer from "./BlogPostContainer";

const Thailand = () => {
    const blogPosts = [
        {
            date: "28.10.2023",
            title: "15. Tag",
            content: (
                <>
                    Herzlich willkommen zu unserem letzten Tag<br/>
                    <br/>
                    Heute gab es wie immer Frühstück und danach noch etwas chillen am Pool. Um 13:15 hat
                    uns das Taxi abgeholt und zum Flughafen gefahren. Dort haben wir noch etwas getrunken
                    und nach ca. 20 Minuten Verspätung konnten wir dann starten. Trotz der Verspätung sind
                    wir on Time in Bangkok gelandet um 17:10.<br/>
                    <br/>
                    Nach dem Transfer zum Hotel für diese Nacht ging es an den local Night Market direkt
                    hier neben dem Hotel. Dieser war sehr spannend, da er auf die locals ausgerichtet war.
                    Zum Essen haben wir diverses ausprobiert, es waren verschiedene Überraschungen, da hier Englisch
                    nicht so viel gesprochen wird.<br/>
                    <br/>
                    Nun sind wir im Hotel Zimmer und lesen, schreiben Blog oder duschen gerade. Danach werden
                    wir wohl mal etwas früher schlafen, da wir morgen bereits um 06:25 den Transfer an den
                    Flughafen haben.<br/>
                    <br/>
                    Soooooo das wars schon wieder mit diesem Blog. Wir hören (oder besser lesen) uns dann bei
                    der nächsten Reise wieder :-)<br/>
                    <br/>
                    Eure Rückreisenden
                </>
            ),
            images: [
                "/images/04_Thailand/15.1.jpg",
                "/images/04_Thailand/15.2.jpg",
                "/images/04_Thailand/15.3.jpg",
            ]
        },
        {
            date: "27.10.2023",
            title: "14. Tag",
            content: (
                <>
                    Soo das Ende der Ferien rückt näher<br/>
                    <br/>
                    Unser letzter ganzer Tag in Koh Samui ist fertig. Die Rückreise steht an. Damit der Plan allen
                    etwas klarer ist hier die Zusammenfassung:<br/>
                    <br/>
                    28.10 <br/>
                    Flug Koh Samui - Bangkok<br/>
                    Übernachtung nähe Flughafen<br/>
                    <br/>
                    29.10<br/>
                    Bangkok - Muscat<br/>
                    Muscat - Zürich<br/>
                    <br/>
                    Nun zum heutigen Tag. Morgens sind wir wie immer frühstücken gegangen. Für unseren letzten Tag
                    hat sich das Wetter gedacht und mit einem sehr heissen und sehr sonnigen Tag zu überraschen.
                    Am Nachmittag ging es nacheinander zum Tattoostudio.<br/>
                    <br/>
                    Natürlich darf auch ein gutes Znacht nicht fehlen.<br/>
                    <br/>
                    Soo damit ist auch dieser Tag kurz zusammengefasst. Morgen melden wir uns nochmals aus Bangkok
                    bevor wir Sonntag dann wieder in der Schweiz sind, brrrr kalt ;-)<br/>
                    <br/>
                    Eure Ferienendgeniesser
                </>
            ),
            images: [
                "/images/04_Thailand/14.1.jpeg",
                "/images/04_Thailand/14.2.jpeg",
                "/images/04_Thailand/14.3.jpeg",
            ]
        },
        {
            date: "26.10.2023",
            title: "13. Tag",
            content: (
                <>
                    Hallihallo und Willkommen zu einer neuen Zusammenfassung unseres Tages...<br/>
                    <br/>
                    Heute ging es wieder tauchen, yuhuu. Ich wurde um 08:15 beim Hotel abgeholt und direkt zum
                    Boot gefahren. Heutiges Ziel: KOH TAO, ja richtig gehört Koh Tao und nicht Sail Rock ;-)<br/>
                    <br/>
                    Nach dem Überprüfen der Ausrüstung haben wir es und vorne auf dem Boot bequem gemacht. Nachdem
                    alle Taucher dasselbe gemacht haben ging es los. Die Fahrtzeit zum ersten Tauchplatz betrug etwa
                    1:15h. Kurz vor dem Ziel haben wir sogar noch Delfine gesehen (leider kein Bild Handy war
                    bereits weggeräumt).<br/>
                    <br/>
                    Erster Tauchplatz war Aow Leuk. Ein schöner Tauchplatz, der nicht sehr tief ist. Die Sicht war
                    ideal.<br/>
                    <br/>
                    Mittags gab es wie immer Reis mit Curry und Frühlingsrollen. Nach ca. 1h Oberflächenpause
                    ging es zum nächsten Tauchgang.<br/>
                    <br/>
                    Shark Island, leider hat dieser Platz den Namen nicht wegen der Haie im Wasser erhalten,
                    sondern weil die Felsformation wie eine Haifischflosse aussieht. Auch dies war ein schöner
                    Tauchplatz.<br/>
                    <br/>
                    Nach diesen beiden Tauchgängen ging es an die Rückfahrt. Im Hotel durfte ein Sprung in den
                    Pool natürlich auch nicht fehlen.<br/>
                    <br/>
                    Später am Abend nach dem Abendessen sind wir Dominic im Tattoostudio abholen gegangen
                    (sein Arm ist nun komplett). Danach ging es noch kurz ein paar Sachen shoppen, die
                    wir mit nachhause nehmen wollen.<br/>
                    <br/>
                    Morgen ist bereits der letzte ganze Tag auf Koh Samui ...<br/>
                    <br/>
                    Ich wünsche allen einen schönen Abend und eine gute Nacht<br/>
                    Eure Tattooverrückten und Tauchbegeisterten
                </>
            ),
            images: []
        },
        {
            date: "25.10.2023",
            title: "12. Tag",
            content: (
                <>
                    Hallo zusammen,<br/>
                    <br/>
                    Auch heute wieder sehr wenig zum Erzählen. Wir haben den ganzen Tag am Pool und Meer
                    verbracht. Abends ging es wie immer Abendessen.<br/>
                    <br/>
                    Morgen dann wieder mehr ;-)<br/>
                    <br/>
                    Eure Nichtsmacher
                </>
            ),
            images: []
        },
        {
            date: "24.10.2023",
            title: "11. Tag",
            content: (
                <>
                    Ein neuer Tag auf Koh Samui.<br/>
                    <br/>
                    Heute haben wir wieder einmal nichts gemacht ;-)<br/>
                    <br/>
                    Wir sind wie immer frühstücken gegangen und danach haben wir am, im und um den Pool entspannt.
                    Ab und zu ging es natürlich auch noch ins Meer.<br/>
                    <br/>
                    Abends haben wir uns entschieden zur Lamai Beach zu fahren anstelle von Chaweng. Leider war
                    dies nicht wirklich für viel und wir sind mit einem Taxi zurück nach Chaweng.<br/>
                    <br/>
                    Heute ein wirklich kurzer Eintrag morgen wird nochmals ausgeruht und gelernt bevor ich am
                    Donnerstag nochmals tauchen gehe.<br/>
                    <br/>
                    Schönen Abend wünschen euch<br/>
                    Eure Relaxer
                </>
            ),
            images: [
                "/images/04_Thailand/11.1.jpeg",
                "/images/04_Thailand/11.2.jpeg",
                "/images/04_Thailand/11.3.jpeg",
            ]
        },
        {
            date: "23.10.2023",
            title: "10. Tag",
            content: (
                <>
                    Guten Abend lieber Leser<br/>
                    <br/>
                    Ja heute ist der Blog etwas später als sonst da. Da ich gerade Pause vom Unterricht habe dachte,
                    ich mir nutze ich die doch jetzt.<br/>
                    <br/>
                    Heute sind wir ganz normal Frühstücken gegangen. Bevor wir zum Bungalow zurück sind haben wir
                    noch einen Blick auf die Gewitterwolken geworfen, zuerst dachten wir, jaajaa die ziehen sicher
                    vorbei. Leider war dem dann nicht so und auf dem Weg zurück haben wir schon die ersten Tropfen
                    gespürt. Danach ging es richtig los, hiess für uns relaxen im Zimmer.<br/>
                    <br/>
                    Nachmittags hat sich der Regen dann verzogen und auch wenn bewölkt, konnte man wieder in den Pool
                    und sich draussen aufhalten. Dies haben wir auch so gemacht.<br/>
                    <br/>
                    Abends ging es erneut auf den Night Market hier in Chaweng. Nun habe ich noch ca. eine halbe
                    Stunde Unterricht vor mir, bevor ich dann auch schlafen gehen kann (02:00 Uhr morgens)<br/>
                    <br/>
                    Somit wünsche ich schon mal eine gute Nacht und bis morgen<br/>
                    Eure Nachteule
                </>
            ),
            images: [
                "/images/04_Thailand/10.1.jpeg",
                "/images/04_Thailand/10.2.jpeg",
                "/images/04_Thailand/10.3.jpeg",
                "/images/04_Thailand/10.4.jpeg",
            ]
        },
        {
            date: "22.10.2023",
            title: "9. Tag",
            content: (
                <>
                    Salüü zämme<br/>
                    <br/>
                    Heute ging es für mich wieder tauchen. Wie schon am Freitag hiess das Ziel Sail Rock. Die
                    beiden Tauchgänge waren wie schon vorher schön. Die Sicht war ideal und es hatte nur minimale
                    Strömung. Das Mittagessen (oder eher ein Brunch, wenn man die Zeit betrachtet) war sehr lecker.
                    Anders als beim letzten Mal ging es heute mit einem Motor Katamaran zum Tauchplatz, dadurch
                    hatten wir viel mehr Platz.<br/>
                    <br/>
                    Leider haben sich während dem tauchen 3 meiner Nägel verabschiedet. Aus diesem Grund wurde
                    entschieden, dass wir bei einem Nail Salon vorbeischauen. Nun habe ich schöne frisch gemachte
                    Negeli ;-)<br/>
                    <br/>
                    Ausserdem haben wir den Bungalow gewechselt, da ein etwas grösseres frei geworden ist. Nun haben
                    wir einen schönen grösseren Pool mit Liegestühlen.<br/>
                    <br/>
                    Auch heute war das Abendessen wieder sehr gut. Nun sind wir relativ früh bereits zurück im Hotel,
                    da wir alle etwas müde sind.<br/>
                    <br/>
                    Ich wünsche eine gute Nacht und bis morgen.<br/>
                    Eure müden Ferienmacher
                </>
            ),
            images: [
                "/images/04_Thailand/9.1.jpg",
                "/images/04_Thailand/9.2.jpg",
                "/images/04_Thailand/9.3.jpg",
                "/images/04_Thailand/9.4.jpg",
                "/images/04_Thailand/9.5.jpg",
                "/images/04_Thailand/9.6.jpg",
                "/images/04_Thailand/9.7.jpg",
            ]
        },
        {
            date: "21.10.2023",
            title: "8. Tag",
            content: (
                <>
                    Hello from Koh Samui<br/>
                    <br/>
                    Heute sind wir von Regen geweckt worden. Aus diesem Grund gingen wir etwas später Frühstücken.<br/>
                    <br/>
                    Auf dem heutigen Plan stand ein Ausflug zu den Secret Garden (oder Buddha Garden). Dafür haben
                    wir uns ein Taxi geschnappt, die Fahrt hat ca. 40 Minuten gedauert, um auf den Berg zu kommen.
                    Vor Ort hat es natürlich direkt wieder angefangen zu regnen. Doch wir haben dies natürlich trotzdem
                    durchgezogen ;-)<br/>
                    <br/>
                    Der "Garden" befindet sich im Wald und ist sehr schön anzusehen. Man kann über Steine klettern
                    und alles bestaunen. Die Ruinen sind teilweise gut erhalten und teilweise sehr zerfallen.<br/>
                    <br/>
                    Danach ging es zurück zum Hotel und am Nachmittag hiess es wieder entspannen am Pool und Meer.
                    Abends wieder Znacht in Chaweng.<br/>
                    <br/>
                    Morgen geht es nochmals tauchen.<br/>
                    <br/>
                    Eure Regenspazierer
                </>
            ),
            images: [
                "/images/04_Thailand/8.1.jpg",
                "/images/04_Thailand/8.2.jpg",
                "/images/04_Thailand/8.3.jpg",
                "/images/04_Thailand/8.4.jpg",
                "/images/04_Thailand/8.5.jpg",
                "/images/04_Thailand/8.6.jpg",
                "/images/04_Thailand/8.7.jpg",
                "/images/04_Thailand/8.8.jpg",
                "/images/04_Thailand/8.9.jpg",
                "/images/04_Thailand/8.10.jpg",
                "/images/04_Thailand/8.11.jpg",
            ]
        },
        {
            date: "20.10.2023",
            title: "7. Tag",
            content: (
                <>
                    Guten Abend liebe Leser<br/>
                    <br/>
                    Heute ging es für mich tauchen. Abgeholt wurde ich um 08:10 Uhr direkt beim Hotel.
                    Danach ging es auf direktem Weg zum Speedboat.<br/>
                    <br/>
                    Aufgrund der low Season ist nur ein Speedboat hinausgefahren und anstelle von Koh Tao ging
                    es zum Sail Rock. Nach ungefähr 50 Minuten fahrzeit sind wir angekommen. Also hiess es
                    Ausrüstung an und ab ins Wasser. Wir waren eine Gruppe von 3 Leuten und 1 Guide, somit
                    war es sehr angenehm zu tauchen.<br/>
                    <br/>
                    Zurück auf dem Boot gab es Lunch: Reis, Curry, Chicken und Spring Rolls. Es war wirklich
                    lecker.<br/>
                    <br/>
                    Nun zum zweiten Tauchgang, auch dieser wieder am Sail Rock. Es war ein schöner ruhiger
                    Tauchgang.<br/>
                    <br/>
                    Nachdem ich zurück im Hotel angekommen bin, ging es an den Strand auf der Suche nach Rahel und
                    Dominic.<br/>
                    <br/>
                    Abends sind wir ins Fishermans Village gefahren und haben dort den Night Market besucht.
                    Nun sind wir zurück im Bungalow und schlafen bald.<br/>
                    <br/>
                    Bis morgen<br/>
                    Eure Tauch- und Strandbegeisterten
                </>
            ),
            images: [
                "/images/04_Thailand/7.1.jpg",
                "/images/04_Thailand/7.2.jpg",
                "/images/04_Thailand/7.3.jpg",
                "/images/04_Thailand/7.4.jpg",
            ]
        },
        {
            date: "19.10.2023",
            title: "6. Tag",
            content: (
                <>
                    Ein weiterer Tag in Thailand<br/>
                    <br/>
                    Heute gibt es sehr wenig zu erzählen, denn es war ein Entspannungstag. Morgens ging es Frühstücken
                    und danach für Rahel und Dominic direkt zu den Strandliegen. Laura hat sich nochmals auf Zimmer
                    zurückgezogen, um noch etwas zu lernen. Nach dem Mittag sind wir zusammen eine Runde im Meer
                    baden gegangen. Ein zvieri darf natürlich auch nicht fehlen.<br/>
                    <br/>
                    Abends sind wir erneut nach Chaweng Beach gefahren und haben dort beim Central Samui zu Abend
                    gegessen. Ein Dessert gab es beim Bellagio Coffee und Gelato.<br/>
                    <br/>
                    So das war schon wieder, heute gibt es auch nicht viele Bilder. Morgen gehts für mich tauchen,
                    da kann ich dann hoffentlich etwas mehr schreiben.<br/>
                    <br/>
                    Eure Strandgeniesser
                </>
            ),
            images: [
                "/images/04_Thailand/6.1.jpg",
                "/images/04_Thailand/6.2.jpg",
                "/images/04_Thailand/6.3.jpg",
                "/images/04_Thailand/6.4.jpg",
            ]
        },
        {
            date: "18.10.2023",
            title: "5. Tag",
            content: (
                <>
                    Guten Abend aus dem schönen Koh Samui<br/>
                    <br/>
                    Heute war Umzugstag. Wir sind um 08:00 Uhr vom Hotel abgeholt worden und mit etwas Stau zum
                    Flughafen
                    gefahren. Nach dem Einchecken ging es in die Bangkok Airways Lounge, um auf dem Flug zu warten.
                    Den kurzen Flug haben wir ohne Probleme hinter uns gebracht und danach direkt ein Taxi zum Hotel
                    genommen.<br/>
                    <br/>
                    Nach einem kleinen Abstecher in den 7-Eleven ging es zum Meer.<br/>
                    <br/>
                    Und wie man so schön sagt: "Erst die Arbeit, dann das Vergnügen" hiess es für Laure die restliche
                    Zeit zum Lernen zu nutzen. Bei dieser Aussicht und die Möglichkeit kurz eine Abkühlung zu nehmen
                    geht das aber sehr gut vorwärts.<br/>
                    <br/>
                    Abends nachdem der Regen etwas abgenommen hat (ja ab und zu regnet es auch hier ;-)
                    sind wir dann ins Chaweng Beach Zentrum, um den Abend ausklingen zu lassen.<br/>
                    <br/>
                    So das wars erst mal wieder<br/>
                    Eure Inselhüpfer
                </>
            ),
            images: [
                "/images/04_Thailand/5.1.jpg",
                "/images/04_Thailand/5.2.jpg",
                "/images/04_Thailand/5.3.jpg",
                "/images/04_Thailand/5.4.jpg",
                "/images/04_Thailand/5.5.jpg",
                "/images/04_Thailand/5.6.jpg",
                "/images/04_Thailand/5.7.jpg",
                "/images/04_Thailand/5.8.jpg",
            ]
        },
        {
            date: "17.10.2023",
            title: "4. Tag",
            content: (
                <>
                    Ein neuer Tag einige neue Erlebnisse...<br/>
                    <br/>
                    Heute sind wir um 09:00 Uhr vom Hotel gestartet, unser Ziel:<br/>
                    Maeklong Railway Market<br/>
                    Viele kennen das typische Bild des Zuges, der durch den Markt fahrt und die Stände, die weggeräumt
                    werden müssen.
                    Es war sehr spannend dies nun auch mal in life zu sehen. Nach einem Rundgang durch den Markt ging es
                    weiter.<br/>
                    <br/>
                    Der Damnoen Floating Market hatten wir schon bei einem der ersten Thailandreisen besucht. Nun
                    wollten wir diesen
                    auch Dominic noch zeigen. Leider hat sich seit dem letzten Besuch einiges verändert und ist
                    definitiv zuuu
                    touristisch geworden. Es gibt nur noch einzelne Boote mit Waren und der Rundgang ist mehr ein
                    anfahren der Stände geworden.<br/>
                    <br/>
                    Danach ging es zurück zum Hotel.<br/>
                    <br/>
                    Am späten Nachmittag stand dann noch der Benjakitti Park auf dem Plan. Man kann es sich in etwa wie
                    der Central Park
                    in New York vorstellen, nur etwas strukturierter. Dieser Park dient sowohl als Ruhezone als auch als
                    Sportbereich
                    mit offenen Sporthallen und mehreren Jogging- und auch Velowegen. Es führen unterschiedliche Wege
                    durch den Park,
                    sowohl am Wasser als auch als Skywalk. Uns hat der Park sehr gefallen.<br/>
                    <br/>
                    Und wie immer darf auch ein gutes Znacht nicht fehlen. Heute ging es ins Terminal 21, um Hot Pot zu
                    essen.<br/>
                    <br/>
                    Das wars für heute, morgen geht es für uns bereits nach Koh Samui<br/>
                    Liebe Grüsse <br/>
                    eure Zugausweicher
                </>
            ),
            images: [
                "/images/04_Thailand/4.1.jpg",
                "/images/04_Thailand/4.2.jpg",
                "/images/04_Thailand/4.3.jpg",
                "/images/04_Thailand/4.4.jpg",
                "/images/04_Thailand/4.5.jpg",
                "/images/04_Thailand/4.6.jpg",
                "/images/04_Thailand/4.7.jpg",
                "/images/04_Thailand/4.8.jpg",
            ]
        },
        {
            date: "16.10.2023",
            title: "3. Tag",
            content: (
                <>
                    Sawatdi ka<br/>
                    <br/>
                    Heute mal etwas später als bisher.<br/>
                    <br/>
                    Nach dem Frühstück sind wir sofort los zu unserem heutigen Ziel. Ein Tempel der ausserhalb von
                    Bangkok zu finden ist. Diesen haben wir bisher noch nie besucht.<br/>
                    <br/>
                    Der Weg zu diesem Wat ist etwas aufwändiger. Zuerst sind wir an die Endstation der Metro gefahren
                    und danach mussten
                    wir noch ein Taxi nehmen. Nach etwa 20 Minuten sind wir angekommen, der Wat Samphran ist ein
                    wunderschöner Tempel.
                    Aussen schlängelt sich ein Drache bis auf das Dach. Man kann bis auf das Dach hochlaufen in dem
                    Bauch des Drachen. Daneben findet man einen grossen und sehr schönen Buddha.<br/>
                    <br/>
                    Nach diesem spannenden Ziel haben wir in einer Mall etwas gegessen. Dies war sehr lecker. Danach
                    haben wir uns auf den Rückweg zum Hotel gemacht.<br/>
                    <br/>
                    Nach einer entspannten Pause sind wir los zum Jodd Fairs, ein Night Market. War ein schöner Market
                    aber nicht der typische
                    Nachtmarkt, den man sonst so sieht. Dieser ist viel Modernen und eher wie Pop-Up-Stores aufgebaut,
                    zudem besteht er zu mehr als 50 % aus Verpflegungsständen.<br/>
                    <br/>
                    Nun ist es mittlerweile schon 00:40 Uhr und Zeit zum Schlafen, da es morgen nochmals weiter weg
                    geht. Dazu morgen im Blog dann mehr.<br/>
                    <br/>
                    So dann wünsche ich euch eine gute Nacht<br/>
                    Eure Metrofahrenden
                </>
            ),
            images: [
                "/images/04_Thailand/3.1.jpg",
                "/images/04_Thailand/3.2.jpg",
                "/images/04_Thailand/3.3.jpg",
                "/images/04_Thailand/3.4.jpg",
                "/images/04_Thailand/3.5.jpg",
                "/images/04_Thailand/3.6.jpg",
                "/images/04_Thailand/3.7.jpg",
                "/images/04_Thailand/3.8.jpg",
                "/images/04_Thailand/3.9.jpg",
            ]
        },
        {
            date: "15.10.2023",
            title: "2. Tag",
            content: (
                <>
                    Guten Abend (oder Nachmittag) liebe Daheimgebliebenen<br/>
                    <br/>
                    Unser erster voller Tag in Thailand. Nach dem Aufstehen sind wir gemütlich Frühstücken
                    gegangen.<br/>
                    <br/>
                    Tagesziel heute:<br/>
                    - Wat Saket<br/>
                    - MBK Center<br/>
                    <br/>
                    Der Wat Saket auch Tempel des goldenen Berges (Golden Mount Temple) genannt, wurde auf einem
                    künstlichen Berg
                    erbaut. Er gehört zu dem ältesten Tempel von Bangkok. Dieser Wat ist sehr schön zum Besichtigen und
                    bietet eine
                    schöne Aussicht auf Bangkok. Die Anreise mit den öffentlichen Verkehrsmitteln etwas aufwändiger aber
                    gut machbar.<br/>
                    <br/>
                    Danach ging es mit einem TukTuk zum MBK, wo wir den restlichen Tag verbracht haben. Natürlich durfte
                    auch hier der
                    Besuch um FoodMarket nicht fehlen (ja bei dieser Reise gibt es viele Essensfotos).<br/>
                    <br/>
                    Nun sind wir soeben vom Abendessen zurück im Hotel. Morgen gibt es wieder einen spannenden Ausflug
                    zu einem Tempel,
                    den wir bisher noch nicht besucht haben. Ihr könnt also gespannt sein ;-)<br/>
                    <br/>
                    Bis morgen und eine gute Nacht wünschen euch die <br/>
                    Shopaholics (... mehr oder weniger)
                </>
            ),
            images: [
                "/images/04_Thailand/2.1.jpg",
                "/images/04_Thailand/2.2.jpg",
                "/images/04_Thailand/2.3.jpg",
                "/images/04_Thailand/2.4.jpg",
                "/images/04_Thailand/2.5.jpg",
                "/images/04_Thailand/2.6.jpg",
                "/images/04_Thailand/2.7.jpg",
            ]
        },
        {
            date: "13. - 14.10.2023",
            title: "1. Tag",
            content: (
                <>
                    Hallihallo liebe Leser,<br/>
                    eine weitere Reise beginnt und damit auch ein weiterer Blog zu den Erlebnissen ... Dann fangen wir
                    doch mal an ;-)<br/>
                    <br/>
                    Freitagabend sind wir von Zürich via Muscat nach Bangkok geflogen. Soweit eigentlich alles gut bis
                    auf ein
                    kleines (oder grösseres) Problem. Lauras Brille dachte sich, komm lösen wir doch eine kleine
                    Schraube und lassen
                    das eine Brillenglas rausfallen ... (siehe Bild). Ja gut selber flicken im Flugzeug eher schwierig,
                    also lieber
                    etwas verschwommen sehen und lesen und dann später in Bangkok schauen wie wir dies wieder
                    hinbekommen.<br/>
                    <br/>
                    Angekommen sind wir dann aber gut um ca. 18:00Uhr und waren auch einigermassen schnell durch die
                    Immigration
                    und beim Gepäck. So haben wir ein Taxi zu unserem Hotel genommen. Danach ging es natürlich zum
                    nächsten
                    Optiker, der sooo lieb war die Brille schnell wieder ganz zu machen.<br/>
                    <br/>
                    Und was natürlich auch nicht fehlen darf, ist ein gutes Znacht.<br/>
                    <br/>
                    So das wars erstmal für den ersten Abend, wir melden uns morgen wieder.<br/>
                    <br/>
                    die übermüdeten Reisenden
                </>
            ),
            images: [
                "/images/04_Thailand/1.2.jpg",
                "/images/04_Thailand/1.3.jpg",
                "/images/04_Thailand/1.4.jpg",
                "/images/04_Thailand/1.5.jpg",
                "/images/04_Thailand/1.6.jpg",
                "/images/04_Thailand/1.7.jpg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Thailand</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Thailand;