import BlogPostContainer from "./BlogPostContainer";

const Norwegen = () => {
    const blogPosts = [
        {
            date: "18.09.2024",
            title: "12. Tag",
            content: (
                <>
                    Guten Abend miteinander,
                    <br/><br/>
                    Heute gings weiter Richtung Süden. Auf dem Weg sind wir über die Atlantik Road gefahren. Wie erwartet
                    war es auf den Bilder nicht dasselbe wie wenn man eine Drohne fliegen lässt. Da wir uns aber aufgrund der
                    Restriktionen und Vorgaben gegen die Mitnahme der Drohne entschieden haben, konnten wir die Bilder leider
                    nicht machen.
                    <br/><br/>
                    Heute hatten wir einigermassen viel Sonne am Nachmittag, eine gelungene Abwechslung zum Regen.
                    <br/><br/>
                    Nun stehen wir auf einem Wanderparkplatz um morgen direkt loslegen zu können.
                    <br/><br/>
                    die imAutoSitzenden
                </>
            ),
            images: [
                "/images/07_Norwegen/12.1.jpg",
                "/images/07_Norwegen/12.2.jpeg",
                "/images/07_Norwegen/12.4.jpeg",
                "/images/07_Norwegen/12.5.jpg",
                "/images/07_Norwegen/12.6.jpeg",
            ]
        },
        {
            date: "17.09.2024",
            title: "11. Tag",
            content: (
                <>
                    Hallo,
                    <br/><br/>
                    Heute war, wie gestern erwähnt, unser Fahrtag. Wir sind morgens relativ zeitig los und sind mehr
                    oder weniger durchgefahren mit einigen kleinen Stopps. Zuerst haben wir in Saltstraumen angehalten,
                    um das wirbelnde Wasser zu sehen, leider waren wir dazu zu früh dran. Weiter ging es mit einem Stopp
                    beim Arctic Circle Center am Polarkreis.
                    <br/><br/>
                    Viel mehr gibt es heute nicht zu erzählen.
                    <br/>
                    die Südwärtsfahrenden
                </>
            ),
            images: [
                "/images/07_Norwegen/11.1.jpg",
                "/images/07_Norwegen/11.2.jpeg",
                "/images/07_Norwegen/11.3.jpeg",
                "/images/07_Norwegen/11.4.jpg",
                "/images/07_Norwegen/11.5.jpg",
                "/images/07_Norwegen/11.6.jpg",
            ]
        },
        {
            date: "16.09.2024",
            title: "10. Tag",
            content: (
                <>
                    Welcome on Board,
                    <br/><br/>
                    Heute kommt das Tagebuch von der Fähre aus. Aber alles der Reihe nach...
                    <br/><br/>
                    Heute sind wir mit dem Ziel, bis ans Ende der Lofoten zu fahren, aufgestanden. Nach dem Losfahren
                    gab es eine Planänderung, da wir angesichts des Wetters (naaattüüüürlich bewölkt) keine Lust auf eine Wanderung hatten.
                    So sind wir den Fjorden entlang Richtung Lofotenende gefahren. Unterwegs dann plötzlich Sonne ;-)
                    Wir können uns nun vorstellen, warum die Lofoten so einen guten Ruf haben. Durch das Sonnenlicht ist
                    das Wasser wirklich türkis und zusammen mit den weissen Stränden hat es etwas von der Karibik. <br/>
                    Leider hat die Sonne nicht wirklich lange gegen die Wolken gewonnen, aber wir wollen uns ja nicht beschweren.
                    Immerhin hatten wir etwas Sonne auf den Lofoten, bevor wir abreisen.
                    <br/><br/>
                    Nach einer kurzen Rast im Nirgendwo gings nach Å i Lofoten, dem letzten Städtchen. Dort haben wir in einer
                    Steinofenbäckerei ein Brot gekauft, wir hatten Glück noch etwas zu ergattern. Danach sind wir ins Dorfcafé,
                    natürlich gab es eine Hot Chocolate und ein Gebäck.
                    <br/><br/>
                    Plan war eigentlich morgen Dienstag die Fähre zurück aufs Festland zu nehmen. Während unserer Kaffeepause
                    haben wir online geschaut wegen der Fähre und zu unserem Schock gesehen, dass die Fähre heute und
                    morgen online "Sold out" ist.
                    Online gab es nicht viele Infos, wie viele zur online Buchung freigegeben sind und wie viele Plätze den
                    "Anstehenden" Fahrzeugen ohne Buchung zur Verfügung stehen.
                    Wir haben bei der Reiseplanung mit Absicht die Fähre noch nicht gebucht:<br/>
                    a: weil es Nebensaison und gemäss allen Reisetipp Seiten keine Buchung notwendig ist
                    <br/>
                    b: weil wir uns noch nicht ganz sicher waren wann wir genau weiterreisen wollen
                    <br/><br/>
                    Nach dieser Entdeckung haben wir uns einen Schlachtplan ausgedacht:<br/>
                    Wir fahren zur Fähre und schauen mal, ob es schon Fahrzeuge hat. Falls ja, stellen wir uns ebenfalls schon
                    mal an. Dies mit der Idee, dass wenn wir auf der Fähre heute Abend nicht mehr Platz hätten, wir morgen als
                    eines der ersten Fahrzeuge ohne Reservation einen Platz bekommen. Da Nebensaison ist, gibt es nur zwei
                    Abfahren pro Tag.
                    <br/><br/>
                    So sind wir ziemlich früh also schon am Fährterminal angestanden und musste einige Stunden Zeit absitzen.
                    Also erst mal Wohnungsputz und auf-/umräumen. Danach noch etwas Schule und etwas nicht machen.
                    <br/><br/>
                    Nun sitzen wir in der Fähre, die uns in ca. 3.5h nach Bødo bringt. Von dort geht es südwärts. Die nächsten
                    beiden Tage werden also wieder Fahrtage um Kilometer gutzumachen.
                    <br/><br/>
                    So das wars für heute
                    <br/>
                    die Fährenwartenden
                </>
            ),
            images: [
                "/images/07_Norwegen/10.1.jpg",
                "/images/07_Norwegen/10.2.jpeg",
                "/images/07_Norwegen/10.3.jpg",
                "/images/07_Norwegen/10.4.jpeg",
                "/images/07_Norwegen/10.5.jpeg",
                "/images/07_Norwegen/10.6.jpg",
                "/images/07_Norwegen/10.7.jpg",
                "/images/07_Norwegen/10.8.jpg",
                "/images/07_Norwegen/10.9.jpg",
                "/images/07_Norwegen/10.10.jpeg",
                "/images/07_Norwegen/10.11.jpg",
            ]
        },
        {
            date: "15.09.2024",
            title: "9. Tag",
            content: (
                <>
                    Hallo zusammen,
                    <br/><br/>
                    Heute mal etwas früher als sonst, da wir schon auf dem Camping sind.
                    <br/><br/>
                    Nach einer erholsamen Nacht sind wir um kurz vor neun los. Heute gings an unsere zweite Wanderung.
                    <br/><br/>
                    Los gings auf den Offersøykammen hoch. Gestartet sind wir von einem Parkplatz direkt am Meer. Von dort ging
                    es angenehm einigermassen flach auf den ersten Hügel. Zum Glück hatten wir Handschuhe und Mütze dabei, durch
                    den Wind war es ziemlich Kalt. Seitlich sind wir dann nach vorne Richtung Berg gelaufen.
                    Nach einiger Zeit, angekommen am Fusse des Berges ging es ziemlich Steil hoch bis zum Gipfel. Da es in den letzten
                    Tagen immer wieder geregnet hat war die gesamte Wanderung eine ziemlich Rutschige angelegenheit.
                    Oben angekommen war die Aussicht wieder sehr schön, leider hat die Sonne nicht ganz mitgespielt.
                    <br/><br/>
                    Nach unserer Wanderung sind wir zum Dorf Nusfjord gefahren. Nusfjord ist ein Fischerdörfchen mit den typischen
                    roten Fischerhütten.
                    <br/><br/>
                    Nun stehen wir für diese Nacht wieder auf einem Campingplatz. Hier könnte man Surfen aber uns ist es
                    momentan zu kalt dafür.
                    <br/><br/>
                    Einen schönen Abend wünschen wir euch,
                    <br/>
                    die vom Winde verwehten
                </>
            ),
            images: [
                "/images/07_Norwegen/9.1.jpg",
                "/images/07_Norwegen/9.2.jpg",
                "/images/07_Norwegen/9.3.jpeg",
                "/images/07_Norwegen/9.4.jpeg",
                "/images/07_Norwegen/9.5.jpg",
                "/images/07_Norwegen/9.6.jpeg",
                "/images/07_Norwegen/9.7.jpeg",
            ]
        },
        {
            date: "14.09.2024",
            title: "8. Tag",
            content: (
                <>
                    Guten Abend,
                    <br/><br/>
                    Ablauf von heute:
                    <br/>
                    - Henningsvær
                    <br/>
                    - an der Küste weiter Richtung Lofoten Ende
                    <br/>
                    - Wikingermuseum
                    <br/>
                    - Schlafplatz finden
                    <br/><br/>
                    So sind wir also losgefahren und haben uns nach Henningsvær begeben. Leider hat das Wetter nicht so super
                    mitgespielt und es hat geregnet und sehr stark gewindet. Zum aufwärmen haben wir uns eine heisse Schokolade
                    und eine Zimtschnecke gegönnt.
                    <br/><br/>
                    Nächster Halt das Lofotr Winkingermuseum. Dies war spannend aber wir sind beide nicht die grössten Museumsgänger.
                    Das Areal ist ziemlich gross und im Sommer sicher sehr schön zum Besuchen, jetzt in der Nebensaison haben
                    sie vieles bereits nicht mehr angeboten (Wikingerbootsfahrt, Pfeilbogen, Restaurants).
                    <br/><br/>
                    Nun stehen wir auf einem Stellplatz/Wanderparkplatz. Anfangs waren wir zu zweit, mittlerweile stehen ungefähr
                    sieben weitere Fahrzeuge hier. Zum Glück haben wir uns einen guten Platz gesichert.
                    <br/><br/>
                    Bis morgen,
                    <br/>
                    die Museumsbesucher oder so...
                </>
            ),
            images: [
                "/images/07_Norwegen/8.1.1.jpg",
                "/images/07_Norwegen/8.1.jpeg",
                "/images/07_Norwegen/8.2.jpeg",
                "/images/07_Norwegen/8.3.jpg",
                "/images/07_Norwegen/8.4.jpg",
                "/images/07_Norwegen/8.5.jpeg",
                "/images/07_Norwegen/8.6.jpeg",
                "/images/07_Norwegen/8.7.jpeg",
                "/images/07_Norwegen/8.8.jpg",
            ]
        },
        {
            date: "13.09.2024",
            title: "7. Tag",
            content: (
                <>
                    Hallihallo,
                    <br/><br/>
                    Heute wars ein entspannter Tag, nachdem wir gestern ziemlich spät noch auf Nordlichtjagt waren.
                    Die Nordlichter waren direkt beim VW zu sehen aber wir sind immer wieder nach draussen gegangen um noch einen
                    besseren Blick zu haben.
                    <br/><br/>
                    Das war der Grund wiso wir etwas länger geschlafen haben. Heute ging es los Richtung Lofoten. Um
                    nicht den gleichen Weg wie beim Hochfahren zu nehmen sind wir via Langøya und Hadseløya Richtung
                    Henningsvær. Von da haben wir eine Fähre nach Fiskebøl genommen.
                    <br/><br/>
                    Das Wetter war ziemlich wechselhaft mit überwiegend Regen. Wir sind trotzdem an der Küste entlang gefahren
                    anstelle der Hauptstrasse. So konnten wir etwas Lofoten sehen, sofern der Regen dies zugelassen hat.
                    <br/><br/>
                    Auf den Lofoten sind Stellplätze ziemlich schwer zu finden, aus diesem Grund haben wir uns erneut für einen
                    Camping entschieden.
                    <br/><br/>
                    Bis morgen und einen schönen Abend
                    <br/>
                    Die Lofotenankommer
                </>
            ),
            images: [
                "/images/07_Norwegen/7.1.jpg",
                "/images/07_Norwegen/7.2.jpeg",
                "/images/07_Norwegen/7.3.jpg",
                "/images/07_Norwegen/7.4.jpeg",
                "/images/07_Norwegen/7.5.jpeg",
                "/images/07_Norwegen/7.6.jpeg",
                "/images/07_Norwegen/7.7.jpeg",
                "/images/07_Norwegen/7.8.jpeg",
                "/images/07_Norwegen/7.9.jpeg",
            ]
        },
        {
            date: "12.09.2024",
            title: "6. Tag",
            content: (
                <>
                    Guten Abend miteinander,
                    <br/><br/>
                    Heute ging es am Morgen mit der Fähre nach Lødingen. Dnach sind wir bis an den nördlichsten Punkt unserer
                    Reise gefahren: Andenes.
                    <br/><br/>
                    Angekommen sind wir direkt auf den Wanderparkplatz gefahren, da wir heute die erste Wanderung der
                    Reise gemacht haben.
                    <br/>
                    Ziel: Måtind
                    <br/><br/>
                    Die Wanderung hat mit einem leichten Anstieg begonnen bevor der ars** Teil der Wanderung kam. Wir
                    mussten ziemlich Steil über Felsen hoch Wandern/Klettern. Oben angekommen war der restliche Teil einigermassen
                    angenehm. Nach 8.3km und 02:40h Wanderzeit (Zeit ohne Pausen) sind wir wieder beim Auto angekommen.
                    <br/><br/>
                    Nun stehen wir auf einem süssen Camping Platz mit direkter Sicht und direktem Zugang zum Strand. Morgen geht
                    es dann weiter mit unserem Eintreffen auf den Lofoten.
                    <br/><br/>
                    Die Wanderer
                </>
            ),
            images: [
                "/images/07_Norwegen/6.1.jpg",
                "/images/07_Norwegen/6.2.jpeg",
                "/images/07_Norwegen/6.3.jpeg",
                "/images/07_Norwegen/6.4.jpg",
                "/images/07_Norwegen/6.5.jpeg",
                "/images/07_Norwegen/6.6.jpeg",
                "/images/07_Norwegen/6.7.jpeg",
                "/images/07_Norwegen/6.8.jpg",
                "/images/07_Norwegen/6.9.jpg",
            ]
        },
        {
            date: "11.09.2024",
            title: "5. Tag",
            content: (
                <>
                    Welcome to Norway,
                    <br/><br/>
                    Heute sind wir auf direktem Weg quer zur Grenze nach Norwegen gefahren. Nun heisst es
                    "Welcome to your final destination!".
                    <br/><br/>
                    Unser Plan ist es, via Bognes nach Lødingen und dann weiter nach Andenes. Heute sind wir bis Bognes gekommen.
                    Morgen nehmen wir den Rest in Angriff.
                    <br/><br/>
                    Unterweges war der Szenenwechsel von Schweden nach Norwegen ziemlich gewaltig. Von einem Flachen Land zu
                    Hügel, Bergen und Fjorden.
                    <br/><br/>
                    Viel Spass beim Bilder durchschauen
                    <br/>
                    Die Grenzüberquerer
                </>
            ),
            images: [
                "/images/07_Norwegen/5.1.1.png",
                "/images/07_Norwegen/5.1.jpg",
                "/images/07_Norwegen/5.2.jpeg",
                "/images/07_Norwegen/5.3.jpeg",
                "/images/07_Norwegen/5.4.jpg",
                "/images/07_Norwegen/5.5.jpeg",
                "/images/07_Norwegen/5.6.jpg",
                "/images/07_Norwegen/5.7.jpeg",
            ]
        },
        {
            date: "10.09.2024",
            title: "4. Tag",
            content: (
                <>
                    Hallo und herzlich Willkommen zur neusten Etappe in "Was haben wir heute gemacht"!
                    <br/><br/>
                    Der Tag hat mit Regen begonnen, von den 21 Grad und Sonne gestern sind wir nun bei 10 Grad und Regen.
                    Aufgewacht, aufgeräumt und los.
                    <br/><br/>
                    T8 hat uns über einen Berg geführt. Die Strasse war ziemlich gerade und durch das Wetter in Nebel gehüllt.
                    Uns hat dieser Weg sehr gefallen. T9 führte fast parallel zur normalen Strasse auf der anderen Seite
                    des Flusses entlang.
                    <br/><br/>
                    Das beste heute:<br/>
                    Wir haben einige Rentiere gesehen.
                    <br/><br/>
                    Nach den Tracks sind wir nochmals einige Kilometer Nordwärts gefahren und stehen nun auf einem, von Schweizern
                    geführten, Campingplatz in Sandsjönäs. Wer kurz auf der Karte nachschaut, sieht das wir nun schon ziemlich
                    weit oben angelangt sind.
                    <br/><br/>
                    Morgen geht es weiter, der Plan ist noch nicht ganz abgeschlossen, da wir vielleicht noch einen Abstecher
                    machen wollen. <br/><br/>
                    Das wars für heute, tschüssi und bis morgen
                    <br/><br/>
                    Die Planschmieder
                </>
            ),
            images: [
                "/images/07_Norwegen/4.1.jpeg",
                "/images/07_Norwegen/4.2.jpeg",
                "/images/07_Norwegen/4.3.jpg",
                "/images/07_Norwegen/4.5.jpeg",
                "/images/07_Norwegen/4.6.jpeg",
                "/images/07_Norwegen/4.7.jpg",
                "/images/07_Norwegen/4.8.jpeg",
            ]
        },
        {
            date: "09.09.2024",
            title: "3. Tag",
            content: (
                <>
                    Hallooo,
                    <br/><br/>
                    Auch heute wieder früh los, um einiges an Kilometer zu machen auf unserem Weg zu den Lofoten.
                    Zuerst sind wir ca. 3.5h auf der E45 hochgefahren.
                    <br/><br/>
                    Danach ging es an die Tracks von Trackbook (kleine Nebenstrassen überland, meistens Schotter).Davon
                    haben wir zwei gemacht T6 und T7 und T8 haben wir angefangen. In den Fotos seht ihr in etwa wie das
                    aussieht. Wildtiere haben wir leider noch keine Gesehen, doch mitten im nirgendwo haben wir Kühe gefunden
                    (also fast wie bei uns).
                    <br/><br/>
                    Nun stehen wir wieder an einem See im nirgendwo auf Track Nummer 8. Morgen werden wir den und T9 in
                    Angriff nehmen bevor wir wieder Nordwärts düüüssssen.
                    <br/><br/>
                    Die Holperstrassenfahrer
                </>
            ),
            images: [
                "/images/07_Norwegen/3.1.jpg",
                "/images/07_Norwegen/3.2.jpeg",
                "/images/07_Norwegen/3.3.jpeg",
                "/images/07_Norwegen/3.4.jpeg",
                "/images/07_Norwegen/3.5.jpg",
                "/images/07_Norwegen/3.6.jpg",
                "/images/07_Norwegen/3.7.jpg",
                "/images/07_Norwegen/3.8.jpeg",
            ]
        },
        {
            date: "08.09.2024",
            title: "2. Tag",
            content: (
                <>
                    Guten Abend
                    <br/><br/>
                    Heute sind wir um 08:00 Uhr losgefahren mit Ziel Schweden. Über die Öresundsbron sind wir direkt nach
                    Malmö gefahren. Ein kurzer Abstecher zu Ikea durfte da natürlich auch nicht fehlen.
                    <br/><br/>
                    Weiter immer Richtung Norden. Nun stehen wir auf einem süssen Wanderparkplatz bei einem kleinen See.
                    <br/><br/>
                    Morgen fahren wir dann den ersten Off-Road Track. Das bringt hoffentlich etwas Abwechslung in unseren
                    Tag.
                    <br/><br/>
                    Wir wünschen einen schönen Abend.
                    <br/>
                    Eure Autoreisenden
                </>
            ),
            images: [
                "/images/07_Norwegen/2.1.jpeg",
                "/images/07_Norwegen/2.2.jpg",
                "/images/07_Norwegen/2.3.jpeg",
                "/images/07_Norwegen/2.4.jpeg",
                "/images/07_Norwegen/2.5.jpeg",
            ]
        },
        {
            date: "07.09.2024",
            title: "1. Tag",
            content: (
                <>
                    Hello und herzlich Willkommen auf das neuste Abenteuer
                    <br/>
                    <br/>
                    Kurz vorab ein paar allgemeine Infos. Wir sind in dieser Reise etwas Planloser als sonst Unterwegs.
                    Das kann also bedeuten, dass die Tagebucheinträge sehr unterschiedlich aufgeschaltet werden. Und wenn es
                    mal dazu kommt, dass wir kein Internet Zugang haben, kann es also auch 1-2 Tage dauern.
                    <br/><br/>
                    So nun aber los. Nach dem Hochzeit gestern in Deutschland sind wir heute gegen 09:00 Uhr losgefahren.
                    Immer weiter Richtung Hamburg hat es geheissen. Unterwegs haben wir dann die Route nach Schweden festgelegt.
                    <br/><br/>
                    bis nach Puttgarden und von da mit der Fähre nach Rødby. Hier sind wir nun in Dänemark nach ca. 10h Autofahrt.
                    <br/><br/>
                    Morgen wirds sicher nochmals ein fast reiner Autotag.
                    <br/>
                    Und damit wünsche ich eine gute Nacht.
                    <br/><br/>
                    Bis morgen, <br/>
                    Die Deutschland hinter sich lassenden Reisenden
                </>
            ),
            images: [
                "/images/07_Norwegen/1.1.jpg",
                "/images/07_Norwegen/1.2.jpg",
                "/images/07_Norwegen/1.3.jpeg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Norwegen</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Norwegen;