

const Dashboard_Aboutme = () => {
    return (
        <div className="AboutMeSection container-fluid">
            <div className="row no-gutters">
                <div className="col-12 col-md-6 AboutMeTextCont d-flex align-items-center justify-content-center">
                    <div className="AboutMeText">
                        <h2>About Me</h2>
                        <p>Hallo zusammen mein Name ist Laura. Wenn ihr etwas mehr über mich Erfahren wollt klickt bitte auf den Button.</p>
                        <a href="/aboutMe" className="btn btn-secondary">Mehr über mich...</a>
                    </div>
                </div>
                <div className="col-12 col-md-6 AboutMeImgCont">
                    <img src="/images/Dashboard/DashboardMe.jpg" alt="About Me" className="aboutMeIMG" loading="lazy" rel="preload"/>
                </div>
            </div>
        </div>
    );
};
export default Dashboard_Aboutme