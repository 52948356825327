import React from "react";

const Dashboard_Titelbild = () => {
    return (
        <div className="DashboardCont container-fluid">
            <div className="row no-gutters">
                <div className="col-12 col DashboardImgCont">
                    <img rel="preload" src="/images/Dashboard/Titelbild.jpg" alt="Titelbild" className="dashboardIMG" loading="eager"/>
                </div>
                <div className="col-12 col d-flex align-items-center justify-content-center DashboardTextCont">
                    <div className="DashboardTitelText">
                        <h3>Welcome to my Page!</h3>
                        <p className="blockquote-footer mt-1">Discover the wonders of the world with me...</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Dashboard_Titelbild