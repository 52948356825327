import React from 'react';

const BlogPostContainer = ({ index, title, date, content, images }) => {
    return (
        <div className="mb-5">
            <h2 className="text-center">{title}</h2>
            <p className="text-center text-muted">{date}</p>
            <p>{content}</p>
            <div id="blogIMG_size">
                {images.length > 0 && (
                    <div id={`carousel${index}`} className="carousel slide" data-bs-ride="carousel">
                        <ol className="carousel-indicators">
                            {images.map((_, imgIndex) => (
                                <button
                                    type="button"
                                    key={imgIndex}
                                    data-bs-target={`#carousel${index}`}
                                    data-bs-slide-to={imgIndex}
                                    aria-label={`Slide ${imgIndex + 1}`}
                                    className={imgIndex === 0 ? 'active' : ''}
                                ></button>
                            ))}
                        </ol>

                        <div className="carousel-inner">
                            {images.map((image, imgIndex) => (
                                <div key={imgIndex}
                                     className={`carousel-item ${imgIndex === 0 ? 'active' : ''}`}>
                                    <img src={image} className="d-block w-100"
                                         alt={`Bild ${imgIndex + 1}`} loading="eager" rel="preload"/>
                                </div>
                            ))}
                        </div>

                        <a className="carousel-control-prev" href={`#carousel${index}`}
                           role="button"
                           data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        </a>
                        <a className="carousel-control-next" href={`#carousel${index}`}
                           role="button"
                           data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        </a>
                    </div>
                )}
            </div>
            <hr/>
        </div>
    );
}
export default BlogPostContainer;
