import React from "react";
import Logo from "./Files/Logo.png"
import Insta from "./Files/Instagram_Glyph_White.png"

const Footer = () => {
    return (
        <div className="footerheight container backcolor mt-3">
            <div className="row ">
                <div className="col d-flex align-items-center">
                    <img className={"Logo"} src={Logo} alt="logo"/>
                </div>
                <div className="col d-flex align-items-end text-center">
                    <p>&copy; Laura Zaugg</p>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                    <a href="https://www.instagram.com/laurazaugg/" target="_blank" rel="noopener noreferrer">
                        <img className={"Logo"} src={Insta} alt="Instagram"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
