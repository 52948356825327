import BlogPostContainer from "./BlogPostContainer";

const SriLanka = () => {
    const blogPosts = [
        {
            date: "09.02.2024",
            title: "13. Tag",
            content: (
                <>
                    Hallo vom Flughafen<br/>
                    <br/>
                    Der letzte Tag und mittlerweile bei mir schon die letzten Stunden in Sri Lanka.<br/>
                    <br/>
                    Heute sind wir morgens zum Bazar gefahren und etwas umhergeschlendert. Danach ging es zum Saree
                    kaufen mit Malathy<br/>
                    <br/>
                    Nachdem wir zurück im Hotel waren, hiess es Pooltime. Vor dem Abendessen haben Rushi und ich noch
                    eine kleine Mall besucht.
                    <br/>
                    Nun sitzen wir am Flughafen. Da Malathys Eltern gerne genügend Zeit haben, warten wir nun darauf,
                    dass wir das Gepäck
                    abgeben können.<br/>
                    <br/>
                    Sooooo und damit heisst es wieder Mal, vielen Dank fürs Lesen meines Tagebuches und wir hören/lesen
                    uns auf meiner nächsten
                    Reise wieder.
                </>
            ),
            images: [
                "/images/05_SriLanka/13.1.jpg",
                "/images/05_SriLanka/13.2.jpg",
                "/images/05_SriLanka/13.3.jpg",
                "/images/05_SriLanka/13.4.jpg",
            ]
        },
        {
            date: "08.02.2024",
            title: "12. Tag",
            content: (
                <>
                    Guten Abend aus Colombo<br/>
                    <br/>
                    Heute haben wir etwas ausgeschlafen und haben danach gefrühstückt. Natürlich konnten wir danach
                    nicht lange wiederstehen
                    und sind zum Pool chillen gegangen. <br/>
                    <br/>
                    Am nachmittag haben wir uns entschieden, dass wir etwas shoppen gehen wollen.<br/>
                    <br/>
                    Nach dem Abendessen haben wir uns erneut einen Drink in der Rooftop-Bar gegönnt.
                    <br/>
                    Morgen ist bereits der letzte Tag. Wir fliegen Freitag auf Samstag in der Nacht.<br/>
                    <br/>
                    Nun heisst es Schlafenszeit bei uns. Wir hören/lesen uns morgen noch ein letztes Mal für diese
                    Ferien.<br/>
                    <br/>
                    Schlaft später auch gut, tschüssiiiiii
                </>
            ),
            images: [
                "/images/05_SriLanka/12.1.jpg",
                "/images/05_SriLanka/12.2.jpg",
                "/images/05_SriLanka/12.3.jpg",
                "/images/05_SriLanka/12.4.jpg",
                "/images/05_SriLanka/12.5.jpg",
                "/images/05_SriLanka/12.6.jpg",
            ]
        },
        {
            date: "07.02.2024",
            title: "11. Tag",
            content: (
                <>
                    Next Day<br/>
                    <br/>
                    Heute gibt es nicht viel zu erzählen, denn es war umzugstag. Wir sind von Jaffna nach Colombo.<br/>
                    <br/>
                    Morgens ging es aber erst mal zu Thushanth nachhause, um uns zu verabschieden. Danach ging die Reise
                    los.
                    Unterwegs haben wir zuerst bei Malathys Onkel (Bruder vom Vater) vorbeigeschaut.<br/>
                    Danach fuhren wir ein Stück bevor wir mit einem Verwandten zu Mittag gegessen haben.
                    <br/>
                    Wir waren erneut ungefähr 12 Stunden unterwegs mit den Pausen, bis wir endlich Colombo erreicht
                    haben.<br/>
                    <br/>
                    Im Hotel eingecheckt ging es sogleich zur Rooftop-bar. Dort haben wir uns einen Cocktail
                    gegönnt.<br/>
                    <br/>
                    Nun sind wir alle mehr als ready fürs Bett.<br/>
                    <br/>
                    Gute Nacht zusammen
                </>
            ),
            images: [
                "/images/05_SriLanka/11.1.jpg",
                "/images/05_SriLanka/11.2.jpg",
            ]
        },
        {
            date: "06.02.2024",
            title: "10. Tag",
            content: (
                <>
                    Guten Abend<br/>
                    <br/>
                    Heute gings wieder früh los. Ich bin mit den Eltern und den Tanten von Malathy zu einem Tempel
                    gegangen.<br/>
                    Der Tempel befindet sich auf einer Insel. Die Reise führte uns mit dem Tuk Tuk zur Bushaltestelle,
                    von da haben wir
                    den öffentlichen Bus bis zur Fähre genommen. Danach gings mit dem Schiff zur Insel.<br/>
                    <br/>
                    Nach unserer Ankunft haben wir uns den Tempel angeschaut. Bis zur Zeremonie mussten wir einige Zeit
                    warten. Die Zeremonie
                    selber war spannend aber ziemlich lang und laut.<br/>
                    <br/>
                    Wir haben Zmittag beim Tempel bekommen. Danach ging es für uns zurück zum Hotel.<br/>
                    <br/>
                    Nilavan und ich sind noch kurz in die Stadt gefahren und danach ein Ice essen gegangen.<br/>
                    <br/>
                    Znacht gab es bei der Familie von Rushi.<br/>
                    <br/>
                    Und damit haben wir schon wieder einen Tag weniger in den Ferien.
                </>
            ),
            images: [
                "/images/05_SriLanka/10.1.jpg",
                "/images/05_SriLanka/10.2.jpg",
                "/images/05_SriLanka/10.3.jpg",
                "/images/05_SriLanka/10.4.jpg",
                "/images/05_SriLanka/10.5.jpg",
                "/images/05_SriLanka/10.6.jpg",
                "/images/05_SriLanka/10.7.jpg",
            ]
        },
        {
            date: "05.02.2024",
            title: "9. Tag",
            content: (
                <>
                    Hallo Zusammen<br/>
                    <br/>
                    Heute sind wir etwas später Aufgestanden, da wir gestern Nacht noch auf die sehr gute Idee gekommen
                    sind, dass wir
                    noch ins Meer baden gehen könnten um 02:00 Uhr morgens.<br/>
                    <br/>
                    Nach dem Aufstehen ging es für Rushi und mich an den Pool. Da wir um 12:00 Uhr auschecken mussten
                    haben wir nur kurz etwas
                    gebadet. Danach mussten wir unsere Sachen zusammenpacken. Nach unserem Mittagessen fuhren wir an den
                    Treffpunkt für das Shuttle,
                    da die erste Gruppe von uns schon nach Colombo zurückkehren.<br/>
                    Dort haben wir etwas Zeit am Strand verbracht und einige Fotos gemacht.<br/>
                    <br/>
                    Danach gingen wir etwas Essen. Und somit haben wir uns auf den Rückweg nach Jaffna gemacht.<br/>
                    <br/>
                    Nun sitze ich wieder draussen und versuche mich in die Schule einzuloggen was sich etwas schwer
                    gestaltet.<br/>
                    <br/>
                    Damit wünsche ich euch eine gute Nacht.
                </>
            ),
            images: [
                "/images/05_SriLanka/9.1.jpg",
                "/images/05_SriLanka/9.2.jpg",
                "/images/05_SriLanka/9.3.jpg",
                "/images/05_SriLanka/9.4.jpg",
                "/images/05_SriLanka/9.5.jpg",
                "/images/05_SriLanka/9.6.jpg",
                "/images/05_SriLanka/9.7.jpg",
            ]
        },
        {
            date: "04.02.2024",
            title: "8. Tag",
            content: (
                <>
                    Guten Abend aus Trincomalee<br/>
                    <br/>
                    Auf geht nach Trincomalee. Dazu sind um 06.00 Uhr mit dem Shuttle los. Die Fahrt dauerte ca. 6
                    Stunden. Kurz vor dem Ziel haben
                    wir noch heisse Quellen besucht<br/>
                    <br/>
                    Angekommen haben wir ins Hotel eingecheckt. Danach ging es natürlich auf schnellstem Weg zum Pool
                    und Strand.<br/>
                    Der Strand ist sehr schön aber das Meer ziemlich unruhig. Mir gefällt es trotzdem so. ist lustig in
                    den Wellen zu stehen.<br/>
                    <br/>
                    Nach etwas baden hatten wir alle hunger. Deshalb gingen wir essen, was ziemlich gut war. <br/>
                    <br/>
                    Danach ging es für uns nochmals etwas baden.<br/>
                    Um 20.30 Uhr haben wir uns in der Lobby getroffen, um zusammen Abendessen zu gehen.<br/>
                    <br/>
                    So das wars für heute schon wieder. Morgen geht es am Nachmittag wieder zurück nach Jaffna.<br/>
                    <br/>
                    Eure Meergeniesserin
                </>
            ),
            images: [
                "/images/05_SriLanka/8.1.jpg",
                "/images/05_SriLanka/8.2.jpg",
                "/images/05_SriLanka/8.3.jpg",
                "/images/05_SriLanka/8.4.jpg",
                "/images/05_SriLanka/8.5.jpg",
            ]
        },
        {
            date: "03.02.2024",
            title: "7. Tag",
            content: (
                <>
                    Hallihallo<br/>
                    <br/>
                    Heute habe ich nicht viel zu erzählen. Wir haben etwas ausgeschlafen und haben gefrühstückt.<br/>
                    <br/>
                    Danach sind wir eine Familie Besuchen gegangen. Mittags/Nachmittags gingen wir zu Malathy's Mann
                    essen.<br/>
                    <br/>
                    Mit vollem Magen haben wir uns entschieden erneut zum KKS Beach zu fahren. Dort haben wir etwas
                    gebadet. Abends
                    haben Malathy und ich bei Thushanth zu Abend gegessen.<br/>
                    <br/>
                    Das wars schon für heute. Morgen fahren wir Richtung Süden, wo wir eine Nacht bleiben werden. Morgen
                    dann mehr dazu
                </>
            ),
            images: [
                "/images/05_SriLanka/7.1.jpg",
                "/images/05_SriLanka/7.2.jpg",
                "/images/05_SriLanka/7.3.jpg",
                ]
        },
        {
            date: "02.02.2024",
            title: "6. Tag",
            content: (
                <>
                    Tag der Hochzeit<br/>
                    <br/>
                    Hier ein kurzer Ablaufplan des heutigen Tages:<br/>
                    04:40 Wecker<br/>
                    05:00 Rushi und ich raus aus unserem Zimmer und rein bei Malathy's Cousine. Malathy fängt an sich
                    vorzubereiten.<br/>
                    06:00 Malathy Make-up, Rushi und ich sind uns ebenfalls am bereit machen.<br/>
                    08:00 Wir sind bereit<br/>
                    08:30 Malathy ist bereit<br/>
                    08:45 Abfahrt zur Location<br/>
                    <br/>
                    Die Hochzeit verlief fast reibungslos und es wurde viel geredet und gelacht und fotografiert.<br/>
                    <br/>
                    Da wir bereit kurz nach dem Mittag zurück im Hotel waren, haben wir uns entschieden zum Strand zu
                    fahren. Wir sind zum KKS
                    Beach gefahren. Es war sehr schön dort und das Wasser war sehr warm.<br/>
                    <br/>
                    Nachdem wir zurück im Hotel waren und unsere Sachen deponiert haben, sind wir los zum Abendessen.
                    Bei Tag 5 findet
                    ihr ein Bild von meinem Essen, heute sind wir erneut da essen gegangen. <br/>
                    <br/>
                    So das wars für heute. Ich lade ein paar wenige Fotos hoch, die ich bereits habe.<br/>
                    <br/>
                    Ich wünsche euch eine gute Nacht.
                </>
            ),
            images: [
                "/images/05_SriLanka/6.1.jpg",
                "/images/05_SriLanka/6.2.jpg",
                "/images/05_SriLanka/6.3.jpg",
                "/images/05_SriLanka/6.4.jpg",
                "/images/05_SriLanka/6.5.jpg",
                "/images/05_SriLanka/6.6.jpg",
                "/images/05_SriLanka/6.7.jpg",
                "/images/05_SriLanka/6.8.jpeg",
                "/images/05_SriLanka/6.9.jpg",
                "/images/05_SriLanka/6.10.jpg",
            ]
        },
        {
            date: "01.02.2024",
            title: "5. Tag",
            content: (
                <>
                    Guten Abend<br/>
                    <br/>
                    Heute gingen wir den Tag langsam an. Malathy musste um 09.00 Uhr raus. Ich konnte etwas länger
                    liegen bleiben. Rushi ist um 09.30 Uhr
                    aufgetaucht. Unser erster Auftrag war das Welcome Board mit den Kunstblumen zu dekorieren. Dies
                    haben wir sofort in Angriff genommen.<br/>
                    <br/>
                    Um 10.45 Uhr wurden wir vom Tuk Tuk abgeholt, damit wir uns mit Malathy beim Nagelstudio treffen
                    konnten. Für mich gab es
                    eine Pedicure und für die beiden Anderen eine Mani- und Pedicure. Es war sehr lustig uns mit den
                    Nagelfrauen zu unterhalten.
                    Wir haben uns sehr amüsiert. Zum Schluss gabe es noch eine Schokomilch :-)<br/>
                    <br/>
                    Der Hunger führte uns in ein nahe gelegenes vegetarisches Restaurant. Dort haben wir uns sehr lecker
                    gesättigt. Bei mir gab
                    Dosa mit Käse und Zwiebeln. Ich habe mich mittlerweile sehr gut eingefügt mit meinen Künsten mit der
                    Hand zu essen.
                    Das führt auch regelmässig für Aufsehen in den Restaurants... und auch teilweise für lustige
                    Gespräche.<br/>
                    <br/>
                    Da die Cousine, ihr Mann und der Sohn heute in Jaffna angekommen sind, haben wir uns nach dem Essen
                    auf den Weg zurück zum Hotel
                    gemacht. Dort haben wir etwas Zeit mit ihnen verbracht. Malathy hat später dann mit Thushanth noch
                    etwas unternommen. <br/>
                    <br/>
                    Plötzlich ist die Idee von Ice cream aufgekommen. Aus einer Idee raus haben wir uns entschieden
                    anstelle einer kurzen
                    einfachen Fahrt mit dem Tuk Tuk, lieber zu laufen. So haben wir uns auf den Weg gemacht... für 40
                    Minuten...<br/>
                    Dort angekommen war die Glace um vieles besser, da wir uns dafür auch etwas angestrengt haben.<br/>
                    <br/>
                    Und so sind wir nun zurück im Hotel und warten auf Malathys Rückkehr. Gerade sitze ich draussen und
                    schlürfe etwas
                    Sangria. So warme Abende sind wirklich nicht schlecht.<br/>
                    <br/>
                    Eure Sangriageniesserin
                </>
            ),
            images: [
                "/images/05_SriLanka/5.1.jpg",
                "/images/05_SriLanka/5.2.jpg",
                "/images/05_SriLanka/5.3.jpg",
                "/images/05_SriLanka/5.4.jpg",
                "/images/05_SriLanka/5.5.jpg",
                "/images/05_SriLanka/5.6.jpg",
                "/images/05_SriLanka/5.7.jpg",
                "/images/05_SriLanka/5.8.jpg",
            ]
        },
        {
            date: "31.01.2024",
            title: "4. Tag",
            content: (
                <>
                    "Der frühe Vogel fängt den Wurm" ich sage dazu nur, der Wurm kann mich mal, soviel Hunger habe ich
                    dann trotzdem nicht<br/>
                    <br/>
                    Herzlich willkommen zum heutigen, sehr vollen und vollgepacktem Tag. Wir sind sehr früh gestartet...
                    und damit meine ich auch
                    früh. Um 5 Uhr hat uns der Shuttlebus abgeholt. Danach ging die Reise los. Unser erstes Ziel war ein
                    Haus, gebaut vom
                    Onkel für eine Familie. Bis dahin sind es 2.5 Stunden fahrt. Zwischendurch sind wir etwas
                    frühstücken gegangen.
                    Nach Ankunft wurden wir von der Familie willkommen geheissen und haben wie üblich hier, etwas zu
                    Trinken und Essen bekommen. <br/>
                    <br/>
                    Danach ging es zum einen Hauptziel des Tages. Das Mahadeva Children Home in Kilinochchi<br/>
                    Die ist ein Mädchen Waisenhaus. Auf einem grossen Areal hat es sowohl Unterkünfte als auch eine
                    Schule für die Kinder.
                    Insgesamt arbeiten momentan 83 Personen da. Wir durften einige der Kinder kennenlernen. Hier im
                    Norden hat es wenige Touristen,
                    für viele war es das erste Mal, eine hellhäutige Person zu sehen. Somit hat es auch einige Fragen
                    gegeben. Es war sehr spannend
                    das ganze zu sehen. Ich hoffe, ich bekomme einige der Fotos auf die Homepage. Während wir da waren
                    hat es auch noch geregnet,
                    somit hätte ich auch das erlebt.
                    <br/>
                    Danach ging es weiter zu einer Schule in Iranamadu. Dort wurden wir von den Schülern empfangen. Der
                    Direktor der Schule
                    und die Familie von Malathy haben motivierende Worte an die Schüler gerichtet. <br/>
                    <br/>
                    Auf dem Rückweg haben wir noch einige Zwischenhalte gemacht, um Familienmitglieder und Bekannte zu
                    besuchen. Zudem haben
                    wir ein Grundstück, welches die Tante gekauft hat besichtigt.<br/>
                    <br/>
                    Nach einem seeehhhrr langen Tag sind wir nun um 21.00 Uhr zurück im Hotel. Zu Abend hat es Rotti
                    gegeben, was sehr lecker war.<br/>
                    <br/>
                    Morgen schlafen wir mal etwas länger und haben einen lockeren Tag.<br/>
                    <br/>
                    So ich wünsche euch einen schönen Abend und bis morgen.<br/>
                    <br/>
                    Eure Minibusreisende
                </>
            ),
            images: [
                "/images/05_SriLanka/4.1.jpg",
                "/images/05_SriLanka/4.2.jpg",
                "/images/05_SriLanka/4.3.jpg",
                "/images/05_SriLanka/4.4.jpg",
                "/images/05_SriLanka/4.5.jpg",
                "/images/05_SriLanka/4.6.jpg",
                "/images/05_SriLanka/4.7.jpg",
                "/images/05_SriLanka/4.8.jpg",
                "/images/05_SriLanka/4.9.jpg",
                "/images/05_SriLanka/4.10.jpg",
                "/images/05_SriLanka/4.11.jpg",
            ]
        },
        {
            date: "30.01.2024",
            title: "3. Tag",
            content: (
                <>
                    Greetings from Sri Lanka <br/>
                    <br/>
                    Fangen wir direkt an. Heute stand morgens ein Besuch in einem Tempel auf dem Programm. Dazu sollten
                    wir um 09.30 Uhr
                    von einem Tuk Tuk abgeholt werden. Pünktlich wie ich meistens bin, stand ich um 09.28 Uhr draussen
                    bereit.
                    Von meinen Mitreisenden (Malathy's Bruder und Cousins) keine Spur. Nach mehrmaligen Klopfen mit der
                    Hilfe des
                    Hotelangestellten und des Tuk Tuk Fahrers, öffnete sich die Tür und drei verschlafene Personen kamen
                    zum Vorschein.<br/>
                    <br/>
                    Nachdem sie sich ebenfalls ready gemacht haben, sind wir los zum Tempel. Für mich war es sehr
                    Spannend mit dabei
                    sein zu können. Ich durfte bei allem mitmachen und dabei sein. Zum Abschluss gab es Milchreise,
                    welches Malathys Family
                    früh morgens gekocht haben.<br/>
                    <br/>
                    Nach dem Teil im Tempel waren wir bei Bekannten, dort gab es frische Kokosnuss vom Baum. Zurück im
                    Hotel haben wir
                    uns entschieden Essen zu gehen. Es war lecker aber ziemlich scharf.<br/>
                    <br/>
                    Liebe Grüsse und gute Nacht<br/>
                    <br/>
                    PS: ich habe mitbekommen, dass einige Bilder nicht geladen werden können. Ich schaue mir das Problem
                    an, sobald ich besseren Empfang habe.
                </>
            ),
            images: [
                "/images/05_SriLanka/3.1.jpg",
                "/images/05_SriLanka/3.2.jpg",
            ]
        },
        {
            date: "29.01.2024",
            title: "2. Tag",
            content: (
                <>
                    Guten Abend liebe Leser <br/>
                    <br/>
                    Wie vielleicht schon mitbekommen wurde das mit dem Hochladen um Mittag nicht. Wir sind erstens
                    später in Jaffna
                    angekommen und zweitens haben wir kein WLAN hier. Aus diesem Grund ist es bei euch jetzt 18.20
                    Uhr. <br/>
                    <br/>
                    Und damit kommen wir nun zum heutigen Tag. Auf unserem Weg von Colombo nach Jaffna haben wir ein
                    paar wenige Pausen
                    gemacht. Aus diesem Grund sind wir dann um ca. 16.30 Uhr beim Hotel angekommen. Nachdem wir die
                    Zimmer bezogen hatten,
                    ging es ans Duschen und umziehen. Um 20.00 Uhr haben wir uns dann bei Thushanth's Family eingefunden
                    um zu Abend zu essen.<br/>
                    <br/>
                    Nun sitze ich auf dem Bett und bin bereit zu schlafen. Morgen gibt es, denke ich mehr zum Berichten.<br/>
                    <br/>
                    die übermüdete Reisende
                </>
            ),
            images: [
                "/images/05_SriLanka/2.1.jpg",
                "/images/05_SriLanka/2.2.jpg",
                "/images/05_SriLanka/2.3.jpg",
            ]
        },
        {
            date: "28.01.2024",
            title: "1. Tag",
            content: (
                <>
                    Und nun heisst es wieder, herzlich willkommen zu einem neuen Abenteuer!<br/>
                    Wie vielleicht mitbekommen, dieses Mal ist es eine spannende neue Erfahrung, die ich machen
                    darf.<br/>
                    <br/>
                    Fangen wir an. Unser Flug ging um 08.40 Uhr, bedeutet ich musste Malathy um 04.45 abholen, um dan
                    nach Zürich zu düsen.
                    Nach dem Check-in ging es direkt durch die Sicherheitskontrolle und zum Terminal E. Da wir vor dem
                    Check-in noch
                    warten mussten waren wir direkt pünktlich zum Boarding da. Der erste Flug von Zürich nach Doha
                    verlief wie im Flug (haha)
                    da ich wie Versprochen an der Webseite gearbeitet habe (ja bei dem Belize Tagebuch fehlen die Fotos
                    noch). <br/>
                    <br/>
                    In Doha angekommen hatten wir einen Aufenthalt von ca. drei Stunden. Dank Rushi konnten wir in die
                    Silver Lounge ;-)<br/>
                    ist empfehlenswert, bequeme Stühle, ruhig und feines Essen.<br/>
                    <br/>
                    Der zweite Flug von Doha nach Colombo war ebenfalls gut. Ich habe ein kurzes Nickerchen gemacht,
                    Filme geschaut und
                    an der Webseite weitergearbeitet. <br/>
                    <br/>
                    Nach Ankunft in Colombo sind wir durch die Immigration und danach zum Gepäck...
                    Es sind alle Gepäckstücke angekommen... hurraaa<br/>
                    <br/>
                    Und soo sitzen wir jetzt im Bus (kein Minibus wie angenommen) und fahren in den Norden. Momentan
                    sind wir noch ungefähr
                    zwei Stunden von Jaffna entfernt. Unterwegs sind wir seit ca. 10 Stunden mit den Pausen. Wir freuen
                    uns alle auf eine Dusche im Hotel.<br/>
                    <br/>
                    Somit hätten wir den ersten Beitrag auf meiner Reise hinter uns gebracht. Wir hören uns morgen
                    wieder oder besser
                    gesagt heute Abend.<br/>
                    <br/>
                    Liebe Grüsse von der Reisenden zu Luft und Strasse oder so.
                </>
            ),
            images: [
                "/images/05_SriLanka/1.1.jpg",
                "/images/05_SriLanka/1.2.jpg",
                "/images/05_SriLanka/1.3.jpg",
                "/images/05_SriLanka/1.4.jpg",
                "/images/05_SriLanka/1.5.jpg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Sri Lanka</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default SriLanka;