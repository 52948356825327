import React from 'react';
import './Dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard_Titelbild from "./Components/Dashboard_Titelbild";
import Dashboard_Welcome from "./Components/Dashboard_Welcome";
import Dashboard_Aboutme from "./Components/Dashboard_Aboutme";
import Dashboard_LinksToBlog from "./Components/Dashboard_LinksToBlog";

const Dashboard = () => {

    return (
        <div>
            <Dashboard_Titelbild/>
            <hr className="divider"/>
            <Dashboard_Welcome/>
            <hr className="divider"/>
            <Dashboard_Aboutme/>
            <hr className="divider"/>
            <Dashboard_LinksToBlog/>
        </div>
    );
};

export default Dashboard;
