import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import BlogPostContainer from "./BlogPostContainer";

const Mauritius = () => {
    const blogPosts = [
        {
            date: "12.05.2023",
            title: "14. Tag",
            content: (
                <>
                    Herzlich Willkommen zu unserem letzten Tag auf Rodrigues...<br/>
                    <br/>
                    Heute wollten wir um 09:00 Uhr unsere abgebrochene Kitesurf Lektion nachholen. Leider war das Wetter
                    schlecht
                    (starker Regen und Windböen), aus diesem Grund haben wir uns entschieden das Kiten sein zu lassen.
                    Deshalb
                    ging es direkt zum zweiten Punkt auf unserem Plan: Chillen.<br/>
                    <br/>
                    Am Nachmittag haben wir eine Runde Boccia gespielt, Laura hat derweilen die Hundebabys beschäftigt.
                    Alle Puppies haben heute ein neues Zuhause gefunden, wir können nun beruhigt sein.<br/>
                    <br/>
                    So nun warten wir gerade auf unser Dessert, morgen geht unser Flug um 16:00 Uhr und wir werden am
                    Sonntagnachmittag in Zürich landen.<br/>
                    <br/>
                    Damit sagen wir Tschüss und vielen Dank fürs Lesen. Wir hören (lesen) uns im Herbst wieder auf dem
                    neuen Abenteuer Thailand.<br/>
                    Eure Heimreiser
                </>
            ),
            images: [
                "/images/02_Mauritius/14.1.jpg",
                "/images/02_Mauritius/14.2.jpg",
            ]
        },
        {
            date: "11.05.2023",
            title: "13. Tag",
            content: (
                <>
                    Guten Abend<br/>
                    <br/>
                    Heute ging es nach Ille aux Coco, die Vogelinsel. Dazu mussten wir um 09:00 Uhr am Hafen Pointe du
                    Diable sein.
                    Die Anfahrt dazu war 30 Minuten. Danach ging es mit einer Nussschale 1:30h zu der Insel mit
                    Schritttempo.
                    Überraschung, das Boot konnte nicht bis an den Strand fahren und hat uns deshalb 300 Meter vor der
                    Insel
                    rausgeworfen und den Rest mussten wir durch das Wasser laufen.<br/>
                    <br/>
                    Dort angekommen gab es eine kurze Erklärung und wir durften alleine die Insel erkunden. Auf der
                    Insel leben
                    vier Vogelarten. Danach gab es ein feines Mittagessen bevor wir 2h chillen konnten.<br/>
                    <br/>
                    Danach ging es zurück mit dem Boot. Nachdem wir in der Lodge zurück waren, haben wir noch eine Runde
                    Boccia
                    gespielt und eine Runde im Meer gebadet.<br/>
                    <br/>
                    Morgen haben wir unseren letzten vollständigen Tag :-( <br/>
                    Eure Vogelbeobachter
                </>
            ),
            images: [
                "/images/02_Mauritius/13.1.jpg",
                "/images/02_Mauritius/13.2.jpg",
                "/images/02_Mauritius/13.3.JPG",
                "/images/02_Mauritius/13.4.jpg",
                "/images/02_Mauritius/13.5.JPG",
                "/images/02_Mauritius/13.6.JPG",
                "/images/02_Mauritius/13.7.jpg",
            ]
        },
        {
            date: "10.05.2023",
            title: "12. Tag",
            content: (
                <>
                    Mittwoch... es ist schon Mittwoch<br/>
                    <br/>
                    Heute sind wir um 11.00 Uhr zu unserer letzten Lektion Kitesurfen gegangen. Da aber leider sehr
                    wenig Wind war,
                    mussten wir diese in der hälfte abbrechen. Wir können es nun am Freitag nochmals versuchen.<br/>
                    <br/>
                    Da wir nun noch etwas mehr Zeit hatten, haben sich Gina, Nici und Laura auf den Weg gemacht, um die
                    Insel zu
                    erkunden. Zuerst sind wir zum berühmten Strand Trou d'Argent, dafür mussten wir etwas
                    Wandern/Spazieren.
                    Dort haben wir das schöne Wasser genossen. Danach haben wir uns auf den Rückweg gemacht.<br/>
                    <br/>
                    Danach ging es zum Zipline. Gina und Laura haben einen Rundgang mit drei Strecken gemacht. Zuerst
                    400m,
                    dann 200m und 150m. Es war kurz aber schön.<br/>
                    <br/>
                    Das wars schon wieder für heute. Morgen gehts nach Ile aux Cocos.<br/>
                    Eure Adrenalinjunkies
                </>
            ),
            images: [
                "/images/02_Mauritius/12.1.jpeg",
                "/images/02_Mauritius/12.2.jpeg",
                "/images/02_Mauritius/12.3.jpeg",
                "/images/02_Mauritius/12.4.jpeg",
                "/images/02_Mauritius/12.5.jpeg",
                "/images/02_Mauritius/12.6.jpg",
            ]
        },
        {
            date: "09.05.2023",
            title: "11. Tag",
            content: (
                <>
                    Liebe Leserschaft<br/>
                    <br/>
                    Heute sind wir wieder gemütlich Frühstücken gegangen. Danach haben wir uns auf den Weg zu neuen
                    Abendteuern gemacht.<br/>
                    <br/>
                    Unser heutiges Ziel: Francois Leguat Tortoise Reserve<br/>
                    Der Park hat sich dem Erhalt und der Vermehrung von Schildkröten verschrieben. Im Moment leben drei
                    Arten in dem
                    Park. Wir haben eine sehr spannende Tour erhalten. Die Schildies leben in einem natürlichen Canyon,
                    indem sie gut geschützt leben können. Wir konnten die Riesenschildkröten streicheln und mit ihnen
                    Fotos
                    machen. Danach ging es im selben Park in eine Tropfsteinhöhle, eine von 11 Höhlen auf dem
                    Areal.<br/>
                    <br/>
                    Am Schluss haben wir uns entschieden eine Patenschaft für zwei Turtles zu übernehmen. Dafür durften
                    wir
                    noch einmal in den Canyon zu den Riesen und konnten uns einen Aussuchen. Danach haben wir ein
                    Zertifikat erhalten,
                    wir konnten ihnen einen Namen geben und werden auch weiterhin Informationen zu unseren Schildkröten
                    erhalten.
                    Meet Toblerone und Tamaka.<br/>
                    <br/>
                    Danach ging es nochmals nach Port Mathurin, da wir noch Arzneien für Laura's Knie brauchten. Morgen
                    geht es an
                    unsere letzte Lektion fürs Kitesurfen.<br/>
                    <br/>
                    Das wars für heute, wir hören (oder lesen) uns morgen wieder.<br/>
                    Eure Schildkrötenpaten
                </>
            ),
            images: [
                "/images/02_Mauritius/11.1.jpg",
                "/images/02_Mauritius/11.2.jpg",
                "/images/02_Mauritius/11.3.jpg",
                "/images/02_Mauritius/11.4.jpg",
                "/images/02_Mauritius/11.5.jpg",
                "/images/02_Mauritius/11.6.jpg",
                "/images/02_Mauritius/11.7.jpg",
            ]
        },
        {
            date: "08.05.2023",
            title: "10. Tag",
            content: (
                <>
                    Heute war ein ruhiger Tag aufgrund Lauras Knie. Wir sind gemütlich Frühstücken gegangen. Danach hat
                    Laura gelernt.
                    Als sie am Mittag zurück ins Zimmer kam, war zu ihrem erstaunen Nici's verloren geglaubte
                    Sonnenbrille wieder da.
                    Die Kitesurfer haben sie gesucht und gefunden!<br/>
                    <br/>
                    Am Nachmittag haben wir noch besuch bekommen. Gestern haben wir eine Familie kennengelernt. Sie
                    wollten
                    ursprünglich ebenfalls in der Bakwa Lodge nächtigen, leider hatte es keinen Platz mehr. Wir haben
                    sie
                    eingeladen unser Häuschen anschauen zu kommen.<br/>
                    <br/>
                    Nun sitzen wir gerade im Restaurant und geniessen unser Abendessen. Morgen steht wieder etwas mehr
                    Sightseeing auf dem Plan.<br/>
                    <br/>
                    Eure Feriengeniesser
                </>
            ),
            images: [
                "/images/02_Mauritius/10.1.jpg",
                "/images/02_Mauritius/10.2.jpg",
                "/images/02_Mauritius/10.3.jpg",
                "/images/02_Mauritius/10.4.jpg",
            ]
        },
        {
            date: "07.05.2023",
            title: "9. Tag",
            content: (
                <>
                    Guten Abend liebe Daheimgebliebenen<br/>
                    <br/>
                    Heute sind wir um 9 Uhr Zmörgele gegangen. Um 10:30 Uhr mussten wir bei Bouba's Dive Center sein.
                    Der
                    Tauchgang an sich war gut, leider war die Organisation nicht so gut und es gab praktisch kein
                    Briefing.
                    Zudem konnte man Laura's Luftanzeige nicht lesen. Hier werden wir wohl nicht nochmals Tauchen gehen.
                    Nici
                    hat während dem Tauchgang den Strand gesäubert (siehe Bild).<br/>
                    <br/>
                    Am Nachmittag ging es zur zweiten Lektion beim Kitesurfen. Dieses Mal haben wir mit Brett und Kite
                    geübt.
                    Leider hat Laura eine richtige Bruchlandung hingelegt und sich das Knie aufgeschürft und geprellt.
                    Sie hat aber tapfer weitergeübt. Wir hatten mit weiteren Verlusten zu kämpfen. Gina hat ihr Armband
                    verloren
                    und Nici's korrigierte Sonnenbrille liegt nun auch bei den Fischen.<br/>
                    <br/>
                    Eigentlich würde morgen unser Kurs noch weitergehen, aber wir müssen mal schauen wie wir morgen zwäg
                    sind.<br/>
                    <br/>
                    Somit verabschieden wir uns für heute <br/>
                    Eure Bruchlander
                </>
            ),
            images: [
                "/images/02_Mauritius/9.1.jpg",
                "/images/02_Mauritius/9.2.jpg",
            ]
        },
        {
            date: "06.05.2023",
            title: "8. Tag",
            content: (
                <>
                    Liebe Leser<br/>
                    <br/>
                    Heute sind wir etwas früher Frühstücken gegangen, da wir um 9 Uhr bei der Kiteschule sein mussten um
                    bescheid zu
                    sagen ob wir es probieren wollen. Nach unserer Zusage haben wir uns auf den Weg gemacht einen
                    Bankomat zu
                    suchen. Auf der Insel gibt es gaannnzzee drei Stück. Nachdem wir gefühlt die Bank leergeräumt
                    hatten, ging es
                    zurück zur Bakwa Lodge. Dort haben wir uns etwas entspannt. Am Strand wurden wir überfallen... von
                    sehr süssen
                    Welpen (5 Stück siehe Bilder).<br/>
                    <br/>
                    Um 3 Uhr haben die ersten zwei Stunden des Grundkurses angefangen. Leider haben wir davon keine
                    Videos oder Fotos,
                    da wir sehr Konzentriert waren. Uns hat es sehr gefallen.<br/>
                    <br/>
                    Morgen haben wir den ersten Tauchgang und Nachmittags geht es mit dem Kitesurfen weiter. Gina wurde
                    noch zu
                    einem Lagerfeuer eingeladen, mal schauen ob wir da noch vorbeischauen.<br/>
                    <br/>
                    Eure Kitesurfanfänger
                </>
            ),
            images: [
                "/images/02_Mauritius/8.1.jpg",
                "/images/02_Mauritius/8.2.jpg",
                "/images/02_Mauritius/8.3.jpg",
                "/images/02_Mauritius/8.4.jpg",
            ]
        },
        {
            date: "05.05.2023",
            title: "7. Tag",
            content: (
                <>
                    Guten Abend liebe Leser<br/>
                    <br/>
                    Heute war unser erster voller Tag in Rodrigues. Morgens gingen wir in der Lodge Frühstücken. Danach
                    machten wir
                    uns auf den Weg um ein paar Kleinigkeiten Einkaufen zu gehen. Da wir kein Wlan in unserem Zimmer
                    haben, haben wir
                    und entschieden eine SIM-Karte zu kaufen. Dies hat sich als grösseres Unterfangen herausgestellt als
                    gedacht.
                    Wir mussten fast 2h warten bis dies funktioniert hat, da der Telekomanbieter ein Systemproblem
                    hatte. Danach
                    ging es quer über die Insel zurück zu unserem Hotel. Nachdem wir uns umgezogen haben, sind wir am
                    Strand entlang
                    zu dem Tauchcenter gegangen. Dieses war aber leider zu. Auf dem Rückweg haben wir uns betreffend
                    Kitesurfen
                    schlau gemacht.<br/>
                    <br/>
                    Nun chillen wir noch etwas und gehen dann essen. <br/>
                    Die Wlansuchenden
                </>
            ),
            images: [
                "/images/02_Mauritius/7.1.jpg",
                "/images/02_Mauritius/7.2.jpg",
                "/images/02_Mauritius/7.3.jpg",
                "/images/02_Mauritius/7.4.jpg",
                "/images/02_Mauritius/7.5.jpg",
                "/images/02_Mauritius/7.6.jpg",
                "/images/02_Mauritius/7.7.jpg",
                "/images/02_Mauritius/7.8.jpg",
            ]
        },
        {
            date: "04.05.2023",
            title: "6. Tag",
            content: (
                <>
                    Byebye Mauritius und hallo Rodrigues.<br/>
                    <br/>
                    Heute war Umzugstag. Nachdem wir etwas länger geschlafen haben, ging es zum Frühstück. Danach
                    mussten wir richtig
                    packen, damit wir unseren Flug erwischen konnten.<br/>
                    <br/>
                    Der Weg vom Hotel zum Flughafen ging an der Küste entlang. Leider war es bewölkt und es gab immer
                    wieder etwas
                    Regen. Am Flughafen gaben wir das Auto zurück und suchten den Weg zum Check-in. Mit etwas Hilfe
                    haben wir die
                    Check-in-Halle gefunden... Wir waren gaaannnzz alleine am warten. Nach etwa etwa einer Stunde
                    tauchten
                    dann weitere Reisende auf und kurz danach konnten wir das Gepäck abgeben.<br/>
                    <br/>
                    Nach über 40 Minuten Verspätung konnten wir dann boarden, wir mussten auf den Emirates Flug von
                    Dubai warten.
                    Nach Ankunft in Rodrigues hat unser Mietwagen schon auf uns gewartet. Vom Flughafen bis zur Bakwa
                    Lodge hatten
                    wir ca. 30 Minuten. Nun sind wir gerade mit dem Dessert fertig.<br/>
                    <br/>
                    Wir melden und morgen mit mehr von Rodrigues <br/>
                    Eure Strandspazierer
                </>
            ),
            images: [
                "/images/02_Mauritius/6.1.jpg",
                "/images/02_Mauritius/6.2.jpg",
            ]
        },
        {
            date: "03.05.2023",
            title: "5. Tag",
            content: (
                <>
                    Ein weiterer Tag geht dem Ende zu. Heute ist Umzugstag zum letzten Hotel auf Mauritius. Zuerst haben
                    wir
                    Chamarel besucht, dort gibt es die Seven Color Earth und den Chamarel Wasserfall. Gerade die
                    siebenfarbige Erde ist sehr bekannt.<br/>
                    <br/>
                    Als nächstes ging es zum Aussichtspunkt in Baie du Cap. Der Aussichtspunkt Maconde View Point
                    befindet sich auf einem Steinvorsprung. Für das perfekte Foto braucht es auch etwas Körpereinsatz
                    (siehe Nicis Einsatz) ;-)<br/>
                    <br/>
                    Danach haben wir uns auf den Weg zu "Le Domaine de Saint Aubin" gemacht, was sich als schwerer als
                    gedacht
                    herausgestellt hat. Nachdem wir es endlich gefunden haben, konnten wir uns einen kleinen Vortrag
                    über die
                    Vanille herstellung anhören. Danach ging es ins Restaurant, dort haben wir zum einen das
                    Vanillepoulet und zum
                    anderen das Poulet an Rhumsauce gegessen.<br/>
                    <br/>
                    Zum Abschluss haben wir uns auf den Weg Richtung Osten gemacht. Dort haben wir unser letztes Hotel
                    auf Mauritius.
                    Morgen haben wir dann unseren Flug nach Rodrigues.<br/>
                    <br/>
                    Somit verabschieden wir uns für heute und melden uns morgen wieder.<br/>
                    Die Abenteurer
                </>
            ),
            images: [
                "/images/02_Mauritius/5.1.JPG",
                "/images/02_Mauritius/5.2.JPG",
                "/images/02_Mauritius/5.3.JPG",
                "/images/02_Mauritius/5.4.jpg",
                "/images/02_Mauritius/5.5.jpg",
                "/images/02_Mauritius/5.6.JPG",
                "/images/02_Mauritius/5.7.JPG",
                "/images/02_Mauritius/5.8.JPG",
                "/images/02_Mauritius/5.9.jpg",
                "/images/02_Mauritius/5.10.JPG",
                "/images/02_Mauritius/5.11.jpg",
                "/images/02_Mauritius/5.12.jpg",
                "/images/02_Mauritius/5.13.JPG",
                "/images/02_Mauritius/5.14.JPG",
                "/images/02_Mauritius/5.15.jpg",
                "/images/02_Mauritius/5.16.JPG",
                "/images/02_Mauritius/5.17.jpg",
            ]
        },
        {
            date: "02.05.2023",
            title: "4. Tag",
            content: (
                <>
                    Salüüü zämme<br/>
                    Heute gehts ins nächste Hotel im Süden. Auf dem Weg haben wir in Port Louis angehalten. Dort sind
                    wir durch die
                    Regenschirm Alleé geschlendert. Danach ging es zum Market. Viele Esswaren, viele Menschen und viele
                    Gerüche.<br/>
                    <br/>
                    Danach weiter Richtung Süden, mit Stopp auf der Takamaka Boutique Winery. Ein kleiner
                    Weinhersteller,
                    der anstelle Trauben mit Lychees arbeitet.<br/>
                    <br/>
                    Als nächstes ein Abstecher zur Bois Cheri Tea Factory, ein kurzer Rundgang durchs mini Museum (nicht
                    so
                    empfehlenswert). Im Preis inbegriffen war ein Tasting, auch dies war eher mickrig.<br/>
                    <br/>
                    Nun ging es zur nächsten Unterkunft. Jetzt sind wir gerade beim Fürobebier und schreiben euch unsere
                    Abenteuer
                    auf. Nun steht eigentlich nur noch das Znacht auf dem Plan und morgen geht es wieder früh los.<br/>
                    <br/>
                    Damit verabschieden wir uns für heute und Aurevoir Pissoir...
                </>
            ),
            images: [
                "/images/02_Mauritius/4.1.jpg",
                "/images/02_Mauritius/4.2.JPG",
                "/images/02_Mauritius/4.3.jpg",
                "/images/02_Mauritius/4.4.JPG",
                "/images/02_Mauritius/4.5.JPG",
                "/images/02_Mauritius/4.6.JPG",
                "/images/02_Mauritius/4.7.JPG",
                "/images/02_Mauritius/4.8.JPG",
                "/images/02_Mauritius/4.9.JPG",
                "/images/02_Mauritius/4.10.JPG",
                "/images/02_Mauritius/4.11.JPG",
                "/images/02_Mauritius/4.12.JPG",
                "/images/02_Mauritius/4.13.jpg",
                "/images/02_Mauritius/4.14.JPG",
                "/images/02_Mauritius/4.15.jpg",

            ]
        },
        {
            date: "01.05.2023",
            title: "3. Tag",
            content: (
                <>
                    Liebe Daheimgebliebenen<br/>
                    <br/>
                    Heute hatten wir viel vor. <br/>
                    Als erstes ging es in den Pamplemousses Botanic Garden. Mit einem Guide haben wir viel entdeckt.
                    Hier gibt es viele Pflanzen und einige Tiere. Zum einen die grossen Water lilies, aber auch diverse
                    Palmen,
                    Gewürzbäume und auch einige Ebenholzgewächse.<br/>
                    <br/>
                    Als nächstes ging es zum Zuckermuseum. Dort haben wir einiges zum Thema Zucker gelernt. Neben dem
                    Museum gab es
                    ein Restaurant, indem wir gegessen haben.<br/>
                    <br/>
                    Die nächste Station war der schlafende Vulkan "Trou aux Cerf". Leider sieht man nicht mehr viel von
                    dem Vulkan,
                    der Krater ist mittlerweile stark überwuchert und man sieht nur an einigen wenigen Stellen den
                    Boden. Auf
                    unserem Rundgang haben wir noch Besuch von Affen erhalten.<br/>
                    <br/>
                    Next stop: Albion Lighthouse<br/>
                    Hier haben wir den Rest des Nachmittags verbracht. Der Leuchtturm steht an einer Klippe, man kann
                    dort
                    herunterklettern. Leider hatten wir nicht ganz so Glück mit dem Sonnenuntergang.<br/>
                    <br/>
                    Wir hören uns morgen. Viel Spass beim lesen ;-)
                </>
            ),
            images: [
                "/images/02_Mauritius/3.1.JPG",
                "/images/02_Mauritius/3.2.JPG",
                "/images/02_Mauritius/3.3.JPG",
                "/images/02_Mauritius/3.4.JPG",
                "/images/02_Mauritius/3.5.JPG",
                "/images/02_Mauritius/3.6.JPG",
                "/images/02_Mauritius/3.7.JPG",
                "/images/02_Mauritius/3.8.JPG",
                "/images/02_Mauritius/3.9.jpg",
                "/images/02_Mauritius/3.10.JPG",
                "/images/02_Mauritius/3.11.JPG",
                "/images/02_Mauritius/3.12.jpg",
                "/images/02_Mauritius/3.13.jpg",
                "/images/02_Mauritius/3.14.JPG",
                "/images/02_Mauritius/3.15.jpg",
            ]
        },
        {
            date: "30.04.2023",
            title: "2. Tag",
            content: (
                <>
                    Nun weiter im Text, der Flug von Paris nach Mauritius war lang (11h) aber aushaltbar.
                    Alle haben etwas geschlafen ausser die Nachteule (Nici).
                    Nach unserer Ankunft ging es durch die Passkontrolle, dies ging seehhrr lange.
                    Danach haben wir unseren Mietwagen abgeholt. Nach etwas Eingewöhnungszeit ging es dann auch mit dem
                    Linksverkehr.
                    <br/>
                    <br/>
                    Wir sind gut in der ersten Unterkunft angekommen und haben uns ein Bier (Sprite für Laura) gegönnt.
                    Danach ging es auf Erkundungstour. Znacht gab es im Hotel.
                    <br/>
                    <br/>
                    Morgen geht es dann richtig los.
                    <br/>
                    <br/>
                    Schönen Abend und sonnige Grüsse aus Mauritius.
                </>
            ),
            images: [
                "/images/02_Mauritius/2.1.jpg",
                "/images/02_Mauritius/2.2.jpg",
                "/images/02_Mauritius/2.3.jpg",
                "/images/02_Mauritius/2.4.jpg",
                "/images/02_Mauritius/2.5.jpg",
                "/images/02_Mauritius/2.6.jpg",
                "/images/02_Mauritius/2.7.jpg",
            ]
        },
        {
            date: "29.04.2023",
            title: "1. Tag",
            content: (
                <>
                    Hallo lieber Blog <br/>
                    Ein neues Abenteuer beginnt..<br/>
                    <br/>
                    Um 15:00 sind wir von Lommiswil gestartet. Netterweise hat uns Dominic mit dem Auto an den Flughafen
                    gefahren.
                    Dieses Mal sind wir von Basel gestartet.<br/>
                    <br/>
                    Unser Flugplan sieht wie folgt aus:<br/>
                    1. Basel - Paris<br/>
                    2. Paris - Mauritius<br/>
                    <br/>
                    Der Flug von Basel nach Paris war entspannt und die Zeit verging wie im Flug *hahaha <br/>
                    Abflug von Paris war kurz vor Mitternacht, deshalb mehr von der Anreise im nächsten Teil
                </>
            ),
            images: [
                "/images/02_Mauritius/1.1.jpg",
                "/images/02_Mauritius/1.2.jpg",
            ]
        },
    ];

    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Mauritius</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    key={index}
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Mauritius;