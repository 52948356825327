import BlogPostContainer from "./BlogPostContainer";

const Zandvoort = () => {
    const blogPosts = [
        {
            date: "31.03.2024",
            title: "3. Tag",
            content: (
                <>
                    Ausschlafen und ausruhen.
                    <br/>
                    <br/>
                    Später sind wir nochmals nach Zandvoort gefahren um den schnellen Autos auf der Rennstrecke zuzuschauen.
                    Gegönnt wurde natürlich auch, die Glace war super.
                    <br/><br/>
                    Am späten Nachmittag sind wir dann nochmals nach Amsterdam gereist um dort Znacht zu essen. Davor sind wir noch ins
                    Madame Tussaude. Später haben wir uns ins Amsterdamer Nachtleben gestürzt.
                    <br/><br/>
                    Morgen fahren wir bereits zurück. Damit wars das von unserer Kurzen Reise.
                </>
            ),
            images: [
                "/images/06_Zandvoort/3.1.jpg",
                "/images/06_Zandvoort/3.2.jpg",
                "/images/06_Zandvoort/3.3.jpg",
                "/images/06_Zandvoort/3.4.jpg",
                "/images/06_Zandvoort/3.5.jpg",
                "/images/06_Zandvoort/3.6.jpg",
            ]
        },
        {
            date: "30.03.2024",
            title: "2. Tag",
            content: (
                <>
                    Heute ging es in den Keukenhof, dem Tulpenpark. Leider war es etwas frisch und hat immer mal wieder
                    geregnet. Trotzdem war es seht schön durch die Tulpen zu Spazieren. Zum Abschluss haben wir noch eine
                    Flussfahrt gemacht, wo uns erklärt wurde wie früher die Tulpen transportiert wurden.
                    <br/>
                    <br/>
                    Im Anschluss sind wir nach Amsterdam mit dem Zug. Dort haben wir geshoppt und zu Abend gegessen.
                </>
            ),
            images: [
                "/images/06_Zandvoort/2.1.jpg",
                "/images/06_Zandvoort/2.2.jpg",
                "/images/06_Zandvoort/2.3.jpg",
                "/images/06_Zandvoort/2.4.jpg",
                "/images/06_Zandvoort/2.5.jpg",
                "/images/06_Zandvoort/2.6.jpg",
                "/images/06_Zandvoort/2.7.jpg",
            ]
        },
        {
            date: "29.03.2024",
            title: "1. Tag",
            content: (
                <>
                    Wir sind gestern schon Mal Richtung Deutschland gefahren. Aus diesem Grund sind wir heute von Deutschland
                    aus gestartet. Mitte Nachmittag sind wir dann beim AirBnB angekommen. Das AirBnB ist sehr süss eingerichtet
                    mit einer kleinen Küche und einem relativ grossem Bad.
                    <br/>
                    <br/>
                    Nachdem wir etwas ausgeruht haben sind wir nach Zandvoort gefahren. Dort haben wir am Strand entlang geschlendert.
                    Als bei uns der Hunger gekommen ist, ging es in ein Restaurant am Strand wo es mega gute Burger gab.
                </>
            ),
            images: [
                "/images/06_Zandvoort/1.1.jpg",
                "/images/06_Zandvoort/1.2.jpg",
                "/images/06_Zandvoort/1.3.jpg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Zandvoort</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Zandvoort;