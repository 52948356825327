import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './Components/reportWebVitals';
import { RouterProvider } from "react-router-dom";
import store from "./Components/store";
import Header_Navbar from "./Components/Header_Navbar";
import Footer from "./Components/Footer";
import { router } from "./Components/router";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <div className="container">
                <Header_Navbar/>
                <RouterProvider router={router} />
                <Footer />
            </div>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
