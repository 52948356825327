import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import BlogPostContainer from "./BlogPostContainer";

const Belize = () => {
    const blogPosts = [
        {
            date: "29.10.2022",
            title: "15. Tag",
            content: (
                <>
                    Guten morgen und willkommen zu unserem letzten Tag.<br/>
                    <br/>
                    Heute haben wir einen
                    Schnorchel-Trip auf einem Segelboot vor uns. Nachdem wir ausgeschlafen haben,
                    mussten wir um 9.45 Uhr beim Treffpunkt sein. Nach einer kleinen Instruktion ging
                    es auch schonlos.Die Crew bestand aus Shane, Dane und Nash.<br/>
                    <br/>
                    Erster Stop war
                    ein Algenfeld auf dem viele Manates unterwegs sind. Wir hatten Glück und konnten drei
                    Seekühe bestaunen.<br/>
                    <br/>
                    Der nächste Stop war im berühmten Hol Chan Marine Reserve.
                    Hier haben wir viele Fische angetroffen und hatten sogar noch das Glück einen Turtle
                    zu treffen.<br/>
                    <br/>
                    Nach einen sehr guten Mittagessen (Sandwiches) ging es bereits
                    zum letzten Schnorchelplatz, dem Shark Ray Alley, hier tummeln sich sehr viele
                    Ammenhaie. Wir konnten über einer Schule dieser Haie Schnorcheln, was sehr eindrücklich
                    ist.<br/>
                    <br/>
                    Auf der Rückreise hatten wir sehr viel Glück. Wir sind auf eine
                    grosse Schule Delfine gestossen. Sie spielten um unser Boot herum. Gina ist fast
                    durchgedreht.<br/>
                    <br/>
                    Nachdem wir zurück waren, mussten wir uns ans packen machen.
                    Nun gehen wir noch Abendessen und vielleicht etwas in die Sportbar, da findet
                    heute ein Halloweenparty statt.<br/>
                    <br/>
                    Sooo das wars nun mit unserer Reise, morgen
                    früh nehmen wir einen Inlandflug zum Flughafen und von da geht es via Housten und
                    Frankfurt zurück nach Zürich. Hoffen wir das alles klappt.
                </>
            ),
            images: [
                "/images/01_Belize/15.1.jpg",
                "/images/01_Belize/15.2.jpg",
                "/images/01_Belize/15.3.jpg",
                "/images/01_Belize/15.4.jpg",
            ]
        },
        {
            date: "28.10.2022",
            title: "14. Tag",
            content: (
                <>
                    Auf dem heutigen Plan steht:<br/>
                    1. Tauchen<br/>
                    2. chillen<br/>
                    3. Sonnenuntergang<br/>
                    4. Nachtessen<br/>
                    <br/>
                    Also los gehts…<br/>
                    <br/>
                    1. Heute sind wir erneut mit Frenchie‘s tauchen gegangen. Dieses
                    Mal nur zwei Tauchgänge. Erster Stop „Hackle Box“, ein sehr schöner Tauchplatz mit
                    vielen grauen und grossen Riffhaien. Der Tauchspot besteh aus drei grossen Canyons. <br/>
                    <br/>
                    Zwischen den beiden Tauchgängen konnten wir einen kleinen Stop auf der Insel
                    San Pedro machen.<br/>
                    <br/>
                    Der zweite Spot den wir heute besucht haben ist „Esmeralda“,
                    dieser befindet sich vor der Küste San Pedros. Auch dieser war sehr schön und auch
                    hier gab es viele neugierige und ein bisschen aufdringliche Riffhaie.<br/>
                    <br/>
                    2.
                    nachdem wir wieder zurück waren (ca. 13.30 Uhr) habe wir unsere Wassermelone
                    verspeist und etwas ausgeruht.<br/>
                    <br/>
                    3. für den heutigen Sonnenuntergang sind
                    wir zum Iguana Reef inn gefahren. Von dort aus hat man einen sehr schönen Ausblick
                    auf den Sonnenuntergang.<br/>
                    <br/>
                    4. das Abendessen haben wir im Restaurant namens
                    „swing“ eingenommen. Natürlich haben wir wiedermal viel zu viel bestellt. Das Essen
                    war gut, aber nicht hervorragend.<br/>
                    <br/>
                    Und so geht unser zweitletzter Abend zu Ende. Gute Nacht und bis morgen
                </>
            ),
            images: [
                "/images/01_Belize/14.1.jpg",
                "/images/01_Belize/14.2.jpg",
            ]
        },
        {
            date: "27.10.2022",
            title: "13. Tag",
            content: (
                <>
                    Heute war ein sehr ruhiger Tag. Wir sind um ca. 7.25 Uhr aufgewacht (ja wir können
                    immer noch nicht länger schlagen) und haben gefrühstückt. Gina hat alles vorbereitet
                    und im Eifer des Gefechts den Rauchmelder ausgelöst ;-) keine Angst wir haben das
                    Cabana nicht abgefackelt.<br/>
                    <br/>
                    Danach haben wir ein paar Anziehsachen zu Ruby‘s
                    Laundry gebracht. Gina ist nach unserer Rückkehr an den Strand gegangen und Laura
                    hat beim Cabana gechillt.<br/>
                    <br/>
                    Am späten Nachmittag sind wir gemeinsam zum Split
                    gefahren. Caye Caulker besteht aus zwei Teilen, die früher verbunden waren. Die Fischer
                    haben für eine schnellere Überfahrt die Mangroven abgeholzt und so eine Schneise
                    geschaffen. 1961 hat Hurrikan Hattie die Schneise verbreitert und vertieft. Seither
                    können auch grössere Schiffe durchfahren. Wenn man auf die andere Seite will,
                    hat es eine Fähre (klein). Da haben wir uns einem Drink gegönnt und den Sonnenuntergang
                    genossen.<br/>
                    <br/>
                    Unser Abendessen war etwas schwieriger. bei zwei Restaurants,
                    die wir vorher ausgesucht hatten, standen wir vor verschlossenen Türen. Am Schluss
                    haben wir aber ein sehr gutes gefunden.<br/>
                    <br/>
                    Morgen dann wieder etwas mehr… <br/>
                    Schlaft gut, tschüssli
                </>
            ),
            images: [
                "/images/01_Belize/13.1.jpg",
                "/images/01_Belize/13.2.jpg",
                "/images/01_Belize/13.3.jpg",
                "/images/01_Belize/13.4.jpg",
                "/images/01_Belize/13.5.jpg",
            ]
        },
        {
            date: "26.10.2022",
            title: "12. Tag",
            content: (
                <>
                    OMG 4.45 Uhr aufstehe, ist definitiv zu früh.<br/>
                    <br/>
                    Um 5.30 mussten wir heute beim
                    Tauchshop sein. Das Frühstück stand schon bereit, natürlich waren wir die ersten.
                    Ein Nutellabrot und ein paar Früchte gemampft und ab aufs Boot. Kaum losgefahren
                    machte der Guide die erste Ansage, vor uns liegen 2h Fahrt, die sehr wellig werden.
                    Bei Ankunft am Blue Hole waren wir das einzige Boot und das Wasser spiegelglatt. <br/>
                    <br/>
                    1. Dive: Great Blue Hole<br/>
                    Das Great Blue Hole ist ein grosses
                    Senkloch vor der Küste Belize. Ein Riffdach, das langsam in eine Sandbank verläuft
                    bevor es senkrecht hinuntergeht. Da wir beide den Deepdiver haben, konnten wir bis
                    auf 40m tauchen. Dieser Tauchplatz hat nicht viele Korallen oder Fische, dafür
                    grosse Stalagmiten und Stalaktiten.<br/>
                    <br/>
                    2. Dive: Halfmoon Wall <br/>
                    Nach dem ersten Tauchgang hatten wir eine kleine Snackpause, während der
                    Fahrt zum nächsten Point. Halfmoon Wall ist der nächste Stopp. Ein schöner
                    Tauchgang mit vielen farbigen und vor allem lebendigen Korallen und Fischen. Wir haben
                    ein paar Riffhaie gesehen.<br/>
                    <br/>
                    3. Dive: Aquarium <br/>
                    Für das Mittagessen sind wir zur Halfmoon Island gefahren. Auf dem Menü stand Poulet,
                    Reis und ein Pastasalat. Wir konnten danach noch etwas relaxen.<br/>
                    <br/>
                    Danach ging
                    es wieder an Bord und weiter zum letzten Spot. Das Aquarium ist ein sehr schöner
                    Tauchplatz. Auch hier gab es viele Korallen und Fische. Und auch hier hatten wir
                    nochmals Glück auf Riffhaie zu treffen. Zudem sahen wir noch einen Turtle
                    davonschwimmen, den wir etwas unsanft mit unserem auftauchen geweckt haben.
                    <br/>
                    <br/>Nachdem wir auch die Rückreise hinter uns gebracht hatten, waren wir
                    dementsprechend etwas kaputt. Das Abendessen haben wir in einem Restaurant
                    namens Reina’s genossen. Danach schlenderten wir noch etwas durch die Hauptgasse
                    und genehmigten uns zwei Kugeln Eis.<br/>
                    <br/>
                    So das wars für heute, morgen
                    gibt es einen Entspannungstag. Tschüssiii
                </>
            ),
            images: [
                "/images/01_Belize/12.1.jpg",
                "/images/01_Belize/12.2.jpg",
            ]
        },
        {
            date: "25.10.2022",
            title: "11. Tag",
            content: (
                <>
                    Hallo zusammen<br/>
                    <br/>
                    Der Tag startete schon am 7.00 Uhr. Da wir heute von
                    Placencia nach Caye Caulker umsiedeln, mussten wir einmal quer durch Belize
                    (natürlich nicht ganz…)<br/>
                    <br/>
                    Unterwegs haben wir uns entschieden, den Zoo von
                    Belize zu besuchen. Die Tiere im Zoo sind alles entweder verletzte, nicht
                    auswilderbare oder ehemalige Haustiere. Die Tiere werden in möglichst Naturnahen
                    Gehegen gehalten. Der Zoo befindet sich in einem Stück Wald, der sehr naturbelassen
                    ist. Die Tiere, die man sehen kann, sind alles einheimische Tiere, die man in Belize
                    findet.<br/>
                    <br/>
                    Nach dem Mittagessen im Zoo, ging es weiter zur Autovermietung,
                    um unseren Wagen zurückzugeben. Danach wurden wir zum Fährenterminal gefahren.
                    Glücklicherweise mussten wir nicht lange auf die Fähre nach Caye Caulker warten.
                    Die Überfahrt dauerte ca. eine Stunde. Auf der Insel gibt es keine Autos, nur
                    Golfcarts. Mit einem von diesen haben wir den Weg zu unserem nächsten AirBnB
                    zurückgelegt.<br/>
                    <br/>
                    Unser Cabana trägt den Namen „The dreaded Grape“, es ist
                    eine ehemalige Weinbar. Da wir morgen Tauchen gehen, mussten wir uns noch beim
                    Tauchshop melden. Dort haben wir unsere Ausrüstung bekommen, damit es morgen seehhhrr
                    früh losgehen kann.<br/>
                    <br/>
                    Soo nun ab ins Bett, da wir morgen um 5.30 Uhr ready
                    beim Tauchshop sein müssen ;-)

                </>
            ),
            images: [
                "/images/01_Belize/11.1.jpg",
                "/images/01_Belize/11.2.jpg",
                "/images/01_Belize/11.3.jpg",
                "/images/01_Belize/11.4.jpg",
                "/images/01_Belize/11.5.jpg",
                "/images/01_Belize/11.6.jpg",
                "/images/01_Belize/11.7.jpg",
                "/images/01_Belize/11.8.jpg",
            ]
        },
        {
            date: "24.10.2022",
            title: "10. Tag",
            content: (
                <>
                    Hallo zusammen,<br/>
                    <br/>
                    Heute mal etwas kürzer… unser letzter Tag in Placencia.
                    Heute stand nichts auf dem Plan, gemütlich starteten wir den Tag mit Pancakes
                    und Früchten. Nachdem Laura Schule hatte, haben wir uns entschieden im Städtchen
                    Mittag zu essen. Heute war es viel zu heiss um etwas zu Unternehmen. Da sehr vieles
                    am Montag geschlossen ist, hatten wir Glück bei einem kleinen Restaurant etwas
                    zu essen zu bekommen.<br/>
                    <br/>
                    Den Rest des Tages haben wir auf unserer Hängematte
                    verbracht. Das wars eigentlich schon von heute. Morgen gehts dann weiter.<br/>
                    <br/>
                    Gute Nacht
                </>
            ),
            images: [
                "/images/01_Belize/10.1.jpg",
                "/images/01_Belize/10.2.jpg",
                "/images/01_Belize/10.3.jpg",
                "/images/01_Belize/10.4.jpg",
            ]
        },
        {
            date: "23.10.2022",
            title: "9. Tag",
            content: (
                <>
                    Soo wie jeden Morgen starten wir den Tag mit einem Frühstück. Heute war dieses
                    etwas kürzer, da wir bereits um 8.00 Uhr am Treffpunkt für unsere Tour sein mussten.
                    <br/>
                    <br/>
                    Der gebuchte Ausflug begann im Herzen von Placencia. Dort trafen wir und mit dem
                    Guide und seinem Boot. Die Anfahrt zu unserem heutigen Ziel „Monkey River“ betrug
                    ca. 40 min durch wunderschöne Mangrovenwälder. Während dieser Anfahrt sahen wir
                    bereits viele Tiere, Belize ist ein beliebtes Ziel für Vogelbeobachter. Nachdem
                    wir den River erreicht hatten, ging es Richtung Regenwald. Unterwegs begegneten
                    wir Krokodilen (wie in den Everglades), vielen Vögeln und Iguanas, sogar drei
                    kleine Schildkröten.
                    <br/>
                    <br/>
                    Der zweite Teil der Tour war eine Wanderung durch den Wald, mit dem Ziel,
                    die Howler Monkeys zu suchen. Erst sah es wirklich schlecht aus, wir haben
                    sie nur von sehr weit weg gehört. Doch plötzlich liess uns der Guide stehen und
                    verschwand für ein paar Minuten. Nach seiner Rückkehr schlug er uns mit seiner
                    Machete einen Weg zu einem Baum frei. Und siehe da, Monkeys. Es war eine kleine
                    Gruppe Howler Monkeys. Zuerst war alles ruhig, doch plötzlich begannen sie
                    herumzuturnen.
                    <br/>
                    <br/>
                    Nachdem wir zum Boot zurückgekehrt sind, waren wir um einige (sehr viele)
                    Mosquitostiche reicher. Nach diesem spannenden Spaziergang machten wir uns auf
                    den Weg zu einer Einheimischen Insel, auf der wir unser Mittagessen geniessen
                    konnten. Auf dem Rückweg nach Placencia hielten wir Ausschau nach Manatees (Seekühen).
                    Nachdem wir einige Zeit umher getuckert sind, habe wir die Hoffnung schon
                    langsam aufgegeben, doch siehe da plötzlich hat unser Guide etwas entdeckt.
                    Wir entdeckten eine Mutter mit ihrem Baby.
                    <br/>
                    <br/>
                    Den Rest des Nachmittages haben wir mit lesen verbracht.
                </>
            ),
            images: [
                "/images/01_Belize/9.1.jpg",
                "/images/01_Belize/9.2.jpg",
                "/images/01_Belize/9.3.jpg",
                "/images/01_Belize/9.4.jpg",
                "/images/01_Belize/9.5.jpg",
                "/images/01_Belize/9.6.jpg",
                "/images/01_Belize/9.7.jpg",
                "/images/01_Belize/9.8.jpg",
                "/images/01_Belize/9.9.jpg",
                "/images/01_Belize/9.10.jpg",
                "/images/01_Belize/9.11.jpg",
                "/images/01_Belize/9.12.jpg",
            ]
        },
        {
            date: "22.10.2022",
            title: "8. Tag",
            content: (
                <>
                    Hallo zusammen
                    <br/>
                    Heute morgen klingelte unser Wecker um 07:45 Uhr. Denn unser
                    heutiges Ziel war mit einer etwas längeren Fahrzeit verbunden.
                    <br/>
                    <br/>
                    Nachdem wir unser Frühstück gegessen haben, fuhren wir um 09:00 Uhr los. Ziel: St.
                    Hermans National Park. Zwei der Attraktionen des National Parks sind die
                    Crystal cave und das Inland Blue Hole. Da wir beide Abenteuer lieben,
                    stand bei uns Heute Cave tubing auf dem Programm.
                    <br/>
                    <br/>
                    Nach einer 2-stündigen Autofahrt sind wir angekommen. Wir bekamen die Reifen und hatten einen etwa
                    10 Minuten Fussweg bis es losging. So, ab in die Reifen und geniessen. Schön
                    ruhig konnten wir denFluss hinuntergleiten. Die Stimmung in der Höhle ist
                    unbeschreiblich…so beruhigend und einfach schön. Da es logischerweise dunkel war,
                    konnten wir leider nicht sehr gute Fotos machen.Nach ca. 30 Minuten im
                    Reifen ging es auch schon wieder zurück zum Ausgangspunkt.
                    <br/>
                    <br/>
                    Natürlich wollten wir nach dem Cave Tubing noch das Inland Blue Hole sehen. Um zum Blue
                    Hole zu gelangen, muss nur ein kurzer Spaziergang gemacht werden. Jedoch war
                    dieser genug lang, um uns von den Moskitos fressen zu lassen. Wir haben beide
                    je etwa 30 Stiche….. Das Blue Hole war heute mehr grün als blau aber
                    dennoch schön.
                    <br/>
                    <br/>
                    Bevor es wieder zurück ins Airbnb geht haben
                    wir am Strassenrand noch kurz ein paar frische Früchte gekauft.
                    <br/>
                    <br/>
                    Nun wünschen wir euch eine gute Nacht und bis morgen.
                </>
            ),
            images: [
                "/images/01_Belize/8.1.jpg",
            ]
        },
        {
            date: "21.10.2022",
            title: "7. Tag",
            content: (
                <>
                    Loooss gehts mit einem neuen Tag. Heute sind wir am morgen bis ans ende der
                    Halbinsel Placencia gefahren, um uns etwas umzusehen. Auf dem Rückweg haben
                    wir bei Barefoot Tours einen Ausflug für am Sonntag gebucht. Natürlich brauchen
                    wir auch hin und wieder etwas zu essen, deshalb sind wir noch einkaufen gegangen.
                    In unserem AirBnB sind wir sehr gut ausgestattet und können uns auch mal etwas
                    selber kochen.
                    <br/>
                    <br/>
                    Kurz vor Mittag sind wir dann losgefahren zu unserem heutigen
                    Ziel. Da wir etwas Hunger hatten, haben wir uns bei einer Strassenverkäuferin
                    einen Burrito und ein Tamales gegönnt. Danach ging es wie immer über einen
                    holprigen Weg zu dem Cockscomb Basin Wildlife Sanctuary, ein Jaguar Preserve
                    Gebiet. Es gibt einige Trails, die man wandern kann (siehe Bilder). Wir haben uns
                    für den Waterfall Trail entschieden. Die Geräusche des Waldes sind sehr eindrücklich.
                    Die Ruhe wurde nur durch laute und unsensible Amerikaner gestört, die Klatschend,
                    laut lachend und stampfen durch den Wald liefen. Nachdem wir am Wasserfall
                    angekommen waren, hatten wir den Trail für uns. Nach einem kurzen bad beim
                    Wasserfall, ging es bereits zurück.
                    <br/>
                    <br/>
                    Am Abend sind wir zu inky‘s essen
                    gegangen. Vor dem Essen haben wir noch eine Runde Minigolf gespielt, welche Laura
                    gewonnen hat. Das Essen war sehr gut, jedoch sehr Amerikanisch.
                    <br/>
                    <br/>
                    Das wars eigentlich schon wieder, gute Nacht und bis zum nächsten Mal
                </>
            ),
            images: [
                "/images/01_Belize/7.1.jpg",
                "/images/01_Belize/7.2.jpg",
                "/images/01_Belize/7.3.jpg",
                "/images/01_Belize/7.4.jpg",
                "/images/01_Belize/7.5.jpg",
                "/images/01_Belize/7.6.jpg",
                "/images/01_Belize/7.7.jpg",
            ]
        },
        {
            date: "20.10.2022",
            title: "6. Tag",
            content: (
                <>
                    Heute war Umzugstag, aber zuerst natürlich noch ein letztes köstliches Frühstück
                    unserer Hosts. Heute gab es Rührei mit Chaya (Spinat von Belize), Bohnen in
                    Kokosnussmilch, Plantains und eines der Nationalgerichte, Fried Jacks, dazu
                    frischer Keylime Juice.
                    <br/>
                    <br/>
                    Nach unserem Frühstück machten wir uns auf den
                    Weg Richtung Placencia. Unterwegs ging es zu Angel falls Xtrem Zipline. Wie ihr
                    vielleicht schon mal mitbekommen habt, hat mir Gina vorgeworfen, dass ich sie
                    umbringen will. Da sie vor angst geschlottert hat, musste Laura den Anfang machen.
                    Wie bereits vermutet macht es rieesssenn spass durch die Baumwipfel zu düsen.
                    Nach der zweiten Zipline hatte Gina dann auch spass.
                    <br/>
                    <br/>
                    Nach diesem Adrenalin
                    Abenteuer ging es weiter Richtung Placencia. Nachdem Gina ihren Job als
                    Navigator nicht ganz nachgekommen ist, mussten wir für das nächste Ziel wenden.
                    Marie sharp‘s ist der berühmteste Hersteller scharfer Sauce in Belize. Wir sind
                    zu der Fabrik gefahren, für 20 BZ konnten wir eine Führung durch die Fabrik machen.
                    Anschliessen durften wir eine Verkostung aller Produkte machen. Natürlich haben wir
                    auch ein paar eingekauft um den daheimgebliebenen einen Einblick zu verschaffen,
                    sobald wir zurück sind.
                    <br/>
                    <br/>
                    Nächster Halt:
                    <br/>
                    unser AirBnB in Placencia.
                    Auch diese Unterkunft ist sehr schön und geräumig. Unterwegs haben wir noch frische
                    Früchte eingekauft.
                    <br/>
                    <br/>
                    Bis morgen und gute Nacht
                </>
            ),
            images: [
                "/images/01_Belize/6.1.jpg",
            ]
        },
        {
            date: "19.10.2022",
            title: "5. Tag",
            content: (
                <>
                    Ein neuer Morgen, ein neuer Tag und ein neues Frühstück (siehe Bild).
                    <br/>
                    <br/>
                    Durch unsere fehlende Morgenmotivation, fuhren wir erst um 9.30 Uhr los.
                    Unser Ziel: Mountain Pine Ridge. Die Anfahrt war deutlich angenehmer als
                    erwartet, das Projekt zur Erschliessung ist weiter fortgeschritten als gedacht.
                    Nur wenige Abschnitte unseres Weges waren nicht asphaltiert.
                    <br/>
                    <br/>
                    Als erstes besuchten wir die Rio on pools. Diese natürlichen Steinbecken laden
                    zum baden ein. Unser nächstes Ziel waren die Five sisters falls, diese befinden
                    sich auf dem Areal der Gaîa Riverlodge. Da es Mittagszeit war, haben wir uns für
                    eine Verpflegung im Restaurant der Riverlodge entschieden. Wie ihr auf dem Bild
                    sehen könnt war es köstlich.
                    <br/>
                    <br/>
                    Um die Wasserfälle von nahen zu sehen, muss man das Lodgeeigene Bähnchen nehmen.
                    Leider war das Hotel komplett voll und wir als nicht Gäste hatten keinen Platz
                    mehr. Nichtsdestotrotz haben wir die Aussicht währen dem Essen auf die
                    Wasserfälle genossen.
                    <br/>
                    <br/>
                    Danach ging es an die Rückfahrt und entspannen auf unserer Terrasse.<br/>
                    <br/>
                    Nun ist unser letzter Tag in San Ignacio zu Ende und wir machen uns morgen auf den Weg zu unserer
                    nächsten Etappe der Reise.
                </>
            ),
            images: [
                "/images/01_Belize/5.1.jpg",
                "/images/01_Belize/5.2.jpg",
                "/images/01_Belize/5.3.jpg",
                "/images/01_Belize/5.4.jpg",
                "/images/01_Belize/5.5.jpg",
            ]
        },
        {
            date: "18.10.2022",
            title: "4. Tag",
            content: (
                <>
                    Hallo zusammen,
                    <br/>
                    <br/>
                    Heute sind wir um 7:50 Uhr für unser Abenteuer abgeholt worden. Die Anreise
                    zu unserem Ziel betrug etwa 1h, davon etwa 30min auf unbefestigten Strassen… Eine
                    holprige Angelegenheit.
                    <br/>
                    <br/>
                    Das spannende Ziel war die ATM Cave. Die Actun
                    Tunichil Muknal (kurz ATM) ist ein Höhlensystem, indem die Mayas Rituale abgehalten
                    haben. Zu sehen sind vor allem Tontöpfe, aber auch fünf grössten teils intakte
                    Skelette/Schädel der Mayas.
                    <br/>
                    <br/>
                    Nun fragt ihr euch sicher, wo die Bilder
                    bleiben… Leider ist es in der Höhle verboten Aufnahmen zu machen. Der Grund dafür
                    ist, dass 2015 ein idiotischer Tourist seine Kamera mitten auf einen der Schädel
                    fallen gelassen hat. Dieser Schädel hat nun ein grosses Loch, die Mayas haben deshalb
                    ein Aufnahmeverbot ausgehängt.
                    <br/>
                    <br/>
                    Sooo kommen wir zu unserem Tag. Nach der
                    Ankunft an der Versammlungsstelle erhielten wir einen Helm mit Lampe und eine
                    Flasche Wasser. Alles übrige mussten wir im Shuttlebus lassen. Der Höhleneingang
                    befindet sich etwa eine 45 Minuten Wanderung entfernt. Kurz nach dem Start
                    schon die erste Überraschung, eine Flussüberquerung. Nach einem kurzen Bad sind
                    wir auf der anderen Seite weitergewandert. Wir mussten noch zweimal den Fluss
                    überqueren bis wir beim Eingang angekommen waren.
                    <br/>
                    <br/>
                    Nach einer kurzen
                    Pause ging es los. Unter anderem mussten wir schwimmen, klettern, eine Leiter
                    erklimmen, kleine Wasserfälle herunterrutschen und uns durch sehr schmale Ritzen
                    quetschen. Es ist wirklich schwer zu erzählen, deshalb hier ein Video von
                    2012: https://youtu.be/3n1ojB6FEFo
                    <br/>
                    <br/>
                    Beide sind jetzt grün und blau und seeehhhr müde. Deshalb wars das schon von uns,
                    allen eine gute Nacht und bis morgen.
                </>
            ),
            images: [
                "/images/01_Belize/4.1.jpg",
                "/images/01_Belize/4.2.jpg",
                "/images/01_Belize/4.3.jpg",
            ]
        },
        {
            date: "17.10.2022",
            title: "3. Tag",
            content: (
                <>
                    Hallo liebe Leserschaft
                    <br/>
                    <br/>
                    Nach einer sehr ruhigen Nacht sind wir um ca. 06:00Uhr morgens aufgewacht:) Jetlag
                    lässt grüssen. Somit konnten wir aber den ersten Morgen in Belize in vollen Zügen
                    geniessen. Natürlich mussten wir den Tag mit der Belizischen Röstung starten. Das
                    Klima in Belize ist ein wenig gewöhnungsbedürftig…Extrem hohe Luftfeuchtigkeit.
                    Da wir unser Frühstück erst für 09:00 Uhr angesetzt haben, hatten wir noch Zeit um
                    die Gegend rund um unser Häuschen zu erkunden. Kurz nachdem wir auf unserer Terrasse
                    entspannt haben, bekamen wir Besuch. Fisty und Buddy, zwei der 5 Hunde die hier
                    Leben. Danach bekamen wir unser Frühstück welches wir am Vorabend bei unseren
                    Hosts bestellt haben. Homemade Hibiscus Saft, frische Papaya, angebratene Plantains
                    und ein Tortilla mit Ei- und Tomatenfüllung. Einfach super Lecker….Ihr könnt in den
                    Bildern sehen wie dieses Frühstück aussah. Nach dieser tollen Stärkung,
                    waren wir bereit für den Tag.
                    <br/>
                    <br/>
                    Erstes Ziel:
                    <br/>
                    Cahal Pech, eine Maya Ruine. Der Fussweg zu dieser Maya Stätte war nicht weit, jedoch
                    mit dieser hohen Luftfeuchtigkeit waren wir beide schon nach ein paarMeternNass;) Es
                    war sehr eindrücklich die Ruinen zu sehen. Cahal Pech ist eine der kleineren Maya
                    Stätten, doch auch diese ist sehr beeindruckend. Da sie sehr guterhalten wurde,
                    kann man als Tourist ohne Einschränkungen auf die Ruinen klettern. Einige Teile
                    dieser Ruinen sind schwer zugänglich und müssen halbwegs beklettert werden (Laura
                    in Action bei den Bildern). Durch die starken Regengüsse der letzen Wochen, konnten
                    wir nur diese Ruine besuchen. Die grössere Maya Stätte „Xunantunich“ war nicht
                    möglich, da die Überquerung des Flusses im Moment nicht erlaubt ist. So nun wieder
                    schnell zum klimatisiertem Auto zurück.
                    <br/>
                    <br/>
                    Nächster Halt:
                    <br/>
                    Ajaw Chocolate & Crafts. Zu Mayazeiten war Schokolade nur für die reichen und edlen
                    Mayas vorgesehen. Traditionell wird in Belize Schokolade ausschliesslich alsGetränk
                    (Wasser)serviert. Der Kakao wird ohne Zucker und ohne Milch hergestellt.Dies weil
                    die Mayas davon ausgehen, dass dies der Schokoladengott so vorgesehen hat. Die
                    Kakaofrucht besteht aus derSchale,darin befinden sich die Nüsse, die für die
                    Schokoladenherstellung gebraucht werden. Um diese Nüsse hat es eine zuckerhaltige
                    Schicht, welche für die Mayas nicht in die Schokolade gehören.Auch heute wird
                    dies noch so gemacht. Nur langsam passen sich die Mayas an und ab 2005 wurde die
                    Schokolade auch als Tafel serviert.
                    <br/>
                    <br/>
                    Nach diesem spannenden Erlebnis
                    machten wir uns auf den Weg zu dem Iguana Conservation Project. In Belize gibt es
                    zwei Arten von Iguanas, den grünen und den schwarzen. Der grüne Leguan steht kurz
                    davor, als gefährdet eingestuft zu werden. Das Projekt dient zur Vermehrung der
                    Leguane und kümmert sich um verletzte Tiere. Die Touren sollen den Besuchern die
                    Tiere näher bringen. Im Moment haben sie eine Gruppe ausgewachsener Leguane, die
                    für die Zucht eingesetzt werden. Sobald die Jungtiere 3 Jahre alt sind, können sie
                    ausgewildert werden.
                    <br/>
                    <br/>
                    Heute haben wir den anderen Weg zum AirBnB genommen.
                    Wie sich herausstellte, ist dieser seehhrr viel angenehmer als unsere erste Anfahrt.
                    Laura fuhr als hätte sie nie etwas anderes gemacht. Nach diesem ausgefüllten Tag,
                    liegen wir nun entspannt auf unserem Bett. Da wir morgen ein grosses Abenteuer
                    vorhaben, sind wir uns am vorbereiten. Seit gespannt…
                    <br/>
                    <br/>
                    Nun wünschen wir euch eine Gute Nacht.
                    <br/>
                    <br/>
                    PS: Heute habe ich meinen Job nicht vernachlässigt, ich habe etwa 180 Fotos geschossen:).
                    Davon stellen wir euch ein Paar auf die Homepage!
                </>
            ),
            images: [
                "/images/01_Belize/3.1.jpg",
                "/images/01_Belize/3.2.jpg",
                "/images/01_Belize/3.3.jpg",
                "/images/01_Belize/3.4.jpg",
                "/images/01_Belize/3.5.jpg",
                "/images/01_Belize/3.6.jpg",
                "/images/01_Belize/3.7.jpg",
                "/images/01_Belize/3.8.jpg",
                "/images/01_Belize/3.9.jpg",
                "/images/01_Belize/3.10.jpg",
                "/images/01_Belize/3.11.jpg",

            ]
        },
        {
            date: "16.10.2022",
            title: "2. Tag",
            content: (
                <>
                    Die Nacht im Motel war angenehm trotz Jetlag. Um 6.45 konnten wir mit einem Shuttle
                    zum Flughafen fahren. Nach einem kleinen Missverständnis beim check-in, war der letzte
                    Flug der Anreise ohne Probleme und mit einer kleinen Verspätung sind wir gut in Belize
                    gelandet.
                    <br/>
                    <br/>
                    Sooo also bereit für die Autoübernahme, die wir Problemlos durchziehen konnten.
                    Natürlich musste es genau jetzt zu schütten anfangen. Doch was will man machen?
                    Augen zu und durch. Das fahren ist eigentlich kein Problem ausser, dass wir uns
                    durchgehen fragten wie schnell wir fahren dürfen? Unnndd natürlich die Speedbumps,
                    die sich als ziemlich hartnäckig herausstellen. Ein kurzer Abstecher zu „the Mall“,
                    die nicht ganz eine Mall ist ;-)
                    <br/><br/>
                    Nachdem wir die Anfahrtsbeschreibung des AirBnB gelesen haben, waren wir gespannt wie
                    Abenteuerlich der Weg wirklich ist…. Es war noch schlimmer.
                    Der sehr steile steinige weg war fast etwas zu viel für unseren 4x4. Nachdem wir
                    endlich oben angekommen sind, hat uns Juliet (unser Host) erklärt, dass es noch
                    einen zweiten, angenehmeren Weg gibt, den wir morgen ausprobieren werden.
                    <br/>
                    <br/>
                    Zum AirBnB gibt es nicht viel zu sagen. Grosses Zimmer, sehr nette Hosts und eine
                    hammer Aussicht, die wir euch morgen zeigen, da Gina ihren Job als Fotograf
                    vernachlässigt hat.
                    <br/><br/>
                    So gute Nacht euch allen wir melden uns morgen wieder.
                </>
            ),
            images: [
                "/images/01_Belize/2.1.jpg",
                "/images/01_Belize/2.2.jpg",
                "/images/01_Belize/2.3.jpg",
                "/images/01_Belize/2.4.jpg",
                "/images/01_Belize/2.5.jpg",
            ]
        },
        {
            date: "15.10.2022",
            title: "1. Tag",
            content: (
                <>
                    Wir fliegen von Zürich nach Houston, Texas via München, Deutschland.
                    <br/><br/>
                    Die Flüge waren sehr angenehm. Nachdem wir in Houston gelandet sind,
                    haben wir ein Taxi zum Motel genommen. Dort angekommen waren wir beide so Müde,
                    dass wir nur noch kurz direkt neben dem Hotel einkaufen gegangen sind. Zum Glück gab es da einen
                    Pizza Laden.
                    <br/><br/>
                    Vor Müdigkeit fast im stehen eingeschlafen sind wir sehr früh schlafen gegangen.
                </>
            ),
            images: [
                "/images/01_Belize/1.1.jpg",
                "/images/01_Belize/1.2.jpg",
                "/images/01_Belize/1.3.jpg",
                "/images/01_Belize/1.4.jpg",
            ]
        },
    ];

    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Belize</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    key={index}
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Belize;